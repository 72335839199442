import React, { useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import "react-phone-input-2/lib/style.css";

const VerifyOtpDialog = ({ isOpen, onClose, getemail }) => {
  const [activeCity, setActiveCity] = useState("Hyderabad");
  const [otp, setOtp] = useState(new Array(4).fill("")); // Array to store OTP digits
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    if (/^[0-9]$/.test(value)) {
      // Only accept numeric input
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index) => {
    if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
    const newOtp = [...otp];
    newOtp[index] = ""; // Clear the current input
    setOtp(newOtp);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-30"
        aria-hidden="true"
      />

      {/* Modal Content */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-4xl w-full bg-white rounded-3xl overflow-hidden shadow-lg flex min-h-[500px]">
          {/* Left Side with Cities and Info */}
          <div className="w-1/2 relative bg-blue-600 text-white p-8 flex flex-col justify-between">
            <div className="relative z-10 w-full">
              {/* Cities with Underline Effect */}
              <div className="flex items-center justify-between text-sm font-medium mb-6">
                {["Guntur", "Chennai", "Hyderabad"].map((city) => (
                  <div
                    key={city}
                    className="relative cursor-pointer"
                    onClick={() => setActiveCity(city)}
                  >
                    <span
                      className={`${
                        activeCity === city
                          ? "text-white mb-2 font-semibold"
                          : "text-gray-200"
                      }`}
                    >
                      {city}
                    </span>
                    <div className="absolute -bottom-2 left-0 right-0 h-[2px] bg-[#FFFFFF33] bg-opacity-15 mt-2 w-24"></div>
                    {activeCity === city && (
                      <div className="absolute -bottom-2 left-0 right-0 h-[2px] bg-white mt-2"></div>
                    )}
                  </div>
                ))}
              </div>

              <span className="cursor-pointer  text-gray-200 hover:underline">
                +24 cities
              </span>

              {/* Background Image with Opacity */}
              <img
                src="/login-image.png" // Replace with the actual background image path
                alt="Background"
                className="absolute -top-24 w-[1200px] h-[600px] object-cover opacity-20"
              />

              {/* Content Card with Rounded Corners and Pagination */}
              <div className="relative z-10 bg-white bg-opacity-90 rounded-2xl p-6 mt-40 max-w-sm mx-auto shadow-md">
                <h2 className="text-2xl  font-CaslonRegular leading-snug text-gray-900">
                  Purchase properties up to 10x faster with SuperNest
                </h2>
                <p className="text-sm mt-4 font-CaslonRegular leading-relaxed text-gray-700">
                  Find your perfect thriving, lively place to call home. Browse
                  hundreds of dorms, hostels, and rental properties ready to
                  move in.
                </p>

                <div className="flex justify-start space-x-2 mt-4">
                  <span className="w-2 h-2 bg-blue-600 rounded-full"></span>
                  <span className="w-2 h-2 bg-gray-300 rounded-full"></span>
                  <span className="w-2 h-2 bg-gray-300 rounded-full"></span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side with OTP Verification */}
          <div className="w-1/2 p-8 flex flex-col relative">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              onClick={onClose}
            >
              ✕
            </button>

            {/* Logo Section */}
            <div className="flex items-center space-x-2">
              <img
                src="/logo.png"
                alt="Logo"
                className="h-6 w-6 md:h-6 md:w-6"
              />
              <h2 className="text-lg md:text-xl">
                <span className="font-bold">supernest</span>.ai
              </h2>
            </div>

            <h3 className="text-2xl font-CaslonRegular text-gray-800 mb-4 text-start mt-6">
              Verify OTP
            </h3>
            <p className="text-gray-600 font-CaslonRegular text-start mb-2">
              OTP has been sent to +91 8074490768.{" "}
              <span className="text-blue-600 cursor-pointer">Change?</span>
            </p>

            {/* OTP Input */}
            {/* <div className="flex justify-between items-center mb-4 mt-4 space-x-2">
              {Array.from({ length: 4 }).map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="w-8 h-8 text-center text-2xl border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                />
              ))}
            </div> */}

            {/* OTP Input */}
            <div className="flex  items-center  mb-4 mt-4 space-x-4 ">
              {otp.map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={otp[index]}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      handleBackspace(index);
                    }
                  }}
                  className="w-10 h-10 text-center text-2xl border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                />
              ))}
            </div>

            {/* Resend OTP */}
            <p className="text-gray-600 font-CaslonRegular text-sm text-start mb-6">
              <span className="text-blue-600 cursor-pointer">Resend OTP</span>{" "}
              if you haven’t received it yet.
            </p>

            {/* Verify Button */}
            <button
              onClick={() => getemail()}
              className="bg-blue-600 text-white  py-2 px-6 w-full rounded-full hover:bg-blue-700"
            >
              Verify
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default VerifyOtpDialog;

/**
 * Fetch properties based on locality, price range, gender, and occupancy.
 *
 * @param {string} locality - The locality to filter properties.
 * @param {number} [minPrice] - The minimum price to filter properties.
 * @param {number} [maxPrice] - The maximum price to filter properties.
 * @param {string} [gender] - The gender preference for the property (e.g., 'Female', 'Male').
 * @param {string} [occupancy] - The occupancy type (e.g., 'Single', 'Double').
 * @returns {Promise<Object>} The response data or an error message.
 */
export async function fetchProperties(filters) {
  const baseUrl =
    "https://supnerstai-ckbfb9hhguafd5au.centralindia-01.azurewebsites.net/discovery/properties";
  const url = new URL(baseUrl);

  // Append query parameters based on filters
  if (filters.locality) url.searchParams.append("locality", filters.locality);
  if (filters.priceRange) {
    url.searchParams.append("minPrice", filters.priceRange[0]);
    url.searchParams.append("maxPrice", filters.priceRange[1]);
  }
  if (filters.gender) url.searchParams.append("gender", filters.gender);
  if (filters.occupancy)
    url.searchParams.append("occupancy", filters.occupancy);
  if (filters.amenities)
    url.searchParams.append("amenities", filters.amenities.join(","));
  if (filters.services)
    url.searchParams.append("services", filters.services.join(","));

  try {
    const response = await fetch(url);
    if (!response.ok) return { error: "Failed to fetch properties" };
    const data = await response.json();
    console.log("🚀 ~ fetchProperties ~ data:", data);
    return { success: true, data };
  } catch (error) {
    return { error: error.message };
  }
}

import React from "react";

const RoomTypeCard = ({ room = {} }) => {
  // Destructure properties with default values
  const {
    typeName = "Type",
    monthlyRent = "₹0/month",
    securityDeposit = "₹0 Deposit",
    selectedGender = "Unknown",
    selectedAmenities = [],
    selectedPhotos = [],
    beds = 0,
    rooms = 0,
  } = room;

  return (
    <div className="bg-white flex justify-center p-4 sm:p-8">
      <div className="w-full p-4">
        <h2 className="text-lg font-medium mb-6 text-gray-800">Room Types</h2>
        <div className="flex flex-col md:flex-row border shadow-md rounded-2xl p-4 sm:p-6">
          {/* Room Image */}
          <div className="w-full md:w-[49%] rounded-xl overflow-hidden mb-4 md:mb-0 md:mr-4">
            {selectedPhotos.length > 0 ? (
              <img
                src={selectedPhotos[0]} // Using the base64 image or URL directly
                alt="Room"
                className="w-full h-48 sm:h-64 md:h-96 object-cover"
              />
            ) : (
              <div className="w-full h-48 sm:h-64 md:h-96 bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500">No Image Available</span>
              </div>
            )}
          </div>

          {/* Room Details */}
          <div className="flex-1 flex flex-col justify-between">
            {/* Room Title and Details */}
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-sm font-semibold text-gray-800">
                  {typeName}
                </h3>
                <div className="flex gap-2 mt-2">
                  <div className="flex items-center bg-blue-100 text-blue-600 text-xs font-medium px-3 py-1 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 11c0-3.866-3.134-7-7-7M5 12a7 7 0 100 14c3.866 0 7-3.134 7-7m0-2c-3.866 0-7-3.134-7-7m14 7a7 7 0 110-14c3.866 0 7-3.134 7 7m0 2a7 7 0 11-14 0m14 0a7 7 0 100-14c3.866 0 7-3.134 7 7"
                      />
                    </svg>
                    {beds} Beds
                  </div>
                  <span className="bg-blue-100 text-blue-600 text-xs font-medium px-3 py-1 rounded-full">
                    {selectedGender}
                  </span>
                </div>
              </div>
            </div>

            {/* Room Description and Pricing Info */}
            <div className="flex flex-col md:flex-row justify-between mb-4">
              {/* Room Description */}
              <div className="mb-4 md:mb-0">
                <h4 className="text-lg sm:text-xl font-semibold text-gray-800">
                  {typeName}
                </h4>
                <p className="text-gray-600 mt-1">{monthlyRent}</p>
                <p className="text-gray-600">{beds} beds</p>
              </div>
              {/* Pricing Details */}
              <div className="text-left md:text-right">
                <p className="text-gray-600">{securityDeposit}</p>
                <p className="text-gray-600">{rooms} rooms</p>
              </div>
            </div>

            {/* Room Amenities */}
            <div className="flex flex-wrap gap-2 mb-4">
              {selectedAmenities.length > 0 ? (
                selectedAmenities.map((amenity, index) => (
                  <span
                    key={index}
                    className="bg-gray-100 text-gray-600 text-sm font-medium px-3 py-1 rounded-full"
                  >
                    {amenity}
                  </span>
                ))
              ) : (
                <span className="text-gray-500">No amenities listed</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomTypeCard;

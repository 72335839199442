import React from "react";

// const ReviewCard = ({ review }) => {
//   return (
//     <div className="flex flex-col border-b pb-4 mb-4">
//       <div className="flex items-center space-x-2 text-gray-600 text-sm">
//         <div className=" flex flex-row items-center justify-between w-full">
//           <div className=" flex flex-row items-center">
//             <div className=" rounded-full bg-gray-200 h-5 w-5 mr-2"></div>
//             <span className="font-semibold">{review.user}</span>
//           </div>

//           <div>
//             <div className=" text-white font-semibold text-sm bg-[#29A653] px-2 py-1 rounded-full">
//               {review.rating}/5.0
//             </div>
//           </div>
//         </div>
//       </div>
//       <p className="text-gray-700 mt-1">{review.comment}</p>
//       <div className="flex justify-between items-center mt-2">
//         <span className="text-gray-400">{review.roomType}</span>
//         <span className="text-gray-400 text-xs">{review.time}</span>
//       </div>
//     </div>
//   );
// };

const Reviews = () => {
  // const feedback = {
  //   guestFeedback: 4.8,
  //   complainResolution: 74,
  // };

  // const tags = [
  //   { text: "clean washrooms", count: 3 },
  //   { text: "nice manager", count: 2 },
  //   { text: "well ventilated space", count: 1 },
  //   { text: "quiet", count: 1 },
  // ];

  // const reviews = [
  //   {
  //     user: "You",
  //     roomType: "Quadruple Premier Room",
  //     comment:
  //       "nah man never go kaze. japanese bull**** filled with rich spoiled people. mirage is waaayyyyy better imo",
  //     rating: 4.2,
  //     time: "32s ago",
  //   },
  //   {
  //     user: "@dontcatchme",
  //     roomType: "Single Premier Room",
  //     comment:
  //       "nah man never go kaze. japanese bull**** filled with rich spoiled people. mirage is waaayyyyy better imo",
  //     rating: 4.7,
  //     time: "2d ago",
  //   },
  //   {
  //     user: "@dontcatchme",
  //     roomType: "Single Premier Room",
  //     comment:
  //       "nah man never go kaze. japanese bull**** filled with rich spoiled people. mirage is waaayyyyy better imo",
  //     rating: 4.7,
  //     time: "2d ago",
  //   },
  // ];

  return (
    <>
      <div className="flex flex-col items-center justify-center h-full">
        <h2 className="text-lg font-semibold text-gray-700 mb-4">
          No Reviews Available
        </h2>
      </div>
      {/* <div className="max-w-[830px]  bg-white  p-6 mb-5 shadow-xl rounded-xl">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Reviews</h2>
        <div className="grid grid-cols-2 gap-4 bg-blue-50 p-6 rounded-lg mb-6">
          <div className="text-start space-y-2">
            <p className="text-gray-600">Guest Feedback</p>
            <h3 className="text-2xl font-CaslonRegular text-black">
              {feedback.guestFeedback}
              <span className="text-gray-400 text-lg">/5.0</span>
            </h3>

            <span className="text-green-600 bg-[#29A65329]  px-2 py-1 rounded-full text-xs">
              GREAT
            </span>
          </div>
          <div className="text-start space-y-2">
            <p className="text-gray-600">Complain Resolution</p>
            <h3 className="text-2xl font-CaslonRegular text-black">
              {feedback.complainResolution}
              <span className="text-gray-400 text-lg">/100</span>
            </h3>
            <span className="text-green-600 bg-[#29A65329] px-2 py-1 rounded-full text-xs">
              GOOD
            </span>
          </div>
        </div>
        <div className="flex items-center mb-4">
          <span className="bg-gray-100 text-gray-700 text-sm font-semibold px-3 py-1 rounded-full mr-4">
            3 COMMENTS
          </span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div className="mb-6">
          <h3 className="text-gray-700 text-sm mb-2 font-semibold">
            What guests say
          </h3>
          <div className="flex flex-wrap gap-2">
            {tags.map((tag, index) => (
              <span
                key={index}
                className="bg-blue-50 text-blue-600 text-xs font-medium px-3 py-2 rounded-full"
              >
                {tag.text} ({tag.count})
              </span>
            ))}
          </div>
        </div>

        <div>
          {reviews.map((review, index) => (
            <ReviewCard key={index} review={review} />
          ))}
        </div>

        <div className="flex justify-center mt-6">
          <button className="bg-blue-100 text-blue-600 px-6 py-3 rounded-full font-medium flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
            <span>Load More</span>
          </button>
        </div>
      </div> */}
    </>
  );
};

export default Reviews;

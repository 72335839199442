/* global google */

import React, { useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import {
  FaBus,
  FaChurch,
  FaSubway,
  FaPlane,
  FaMapMarkerAlt,
} from "react-icons/fa";
// import blueMarker from "../../../assets/images/blue-marker.png";

// Function to generate custom SVG icon URL for each price marker
// const generatePriceMarkerIcon = (price) => {
//   const svgIcon = `
//     <svg width="50" height="30" xmlns="http://www.w3.org/2000/svg">
//       <g>
//         <rect x="0" y="0" width="50" height="30" rx="15" ry="15" fill="#ffffff" />
//         <text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" font-size="14" font-family="Arial" font-weight="bold" fill="#333333">
//           $${price}
//         </text>
//       </g>
//     </svg>
//   `;
//   return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgIcon)}`;
// };

const NearbyPlacesMap = ({ locations, mapCenter }) => {
  const mapRef = useRef(null); // Ref for map container

  useEffect(() => {
    const initMap = async () => {
      const loader = new Loader({
        apiKey: "AIzaSyCceJ_PFXKkrZC0vkJo1GPL1dD2IeB4NPI", // Using environment variable for API key
        version: "weekly",
      });

      // Load Google Maps library
      const { Map } = await loader.importLibrary("maps");

      // Map configuration
      const mapOptions = {
        center: mapCenter,
        zoom: 15,
        mapId: "MY_Nextjs_MAPID", // Customize map styling if you have a map ID
      };

      // Initialize the map in the container
      const map = new Map(mapRef.current, mapOptions);

      // // Create a marker and set its position.
      // new google.maps.Marker({
      //   mapCenter,
      //   map,
      // });

      // Create a marker at the map center and set its position
      // Create a marker at the map center with a different style
      new google.maps.Marker({
        position: mapCenter,
        map,
        icon: {
          // Use a standard Google Maps marker or a different custom icon URL
          // url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Replace with your custom icon URL if needed
          url: require("../../../assets/images/blue-marker.png"), // Use the imported custom marker image
          scaledSize: new google.maps.Size(40, 40), // Adjust the size as needed
        },
      });

      // Add markers to the map based on location data
      locations.forEach((location) => {
        new google.maps.Marker({
          position: {
            lat: location.coordinates.lat,
            lng: location.coordinates.lng,
          },
          map,
          icon: {
            // url: generatePriceMarkerIcon(location?.minRent || "0"),
            url: require("../../../assets/images/white-marker.png"),
            scaledSize: new google.maps.Size(50, 30),
          },
        });
      });
    };

    initMap();
  }, [locations, mapCenter]);

  return (
    <div className="max-w-[830px] my-10">
      {/* Heading and Map Link */}
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-sm font-semibold text-gray-700">Locality</h3>
        <button className="bg-[rgb(232,240,254)] text-blue-600 text-xs font-medium px-3 py-1 rounded-full hover:bg-blue-100 flex flex-row items-center">
          <FaMapMarkerAlt className="mr-1" /> View on Maps
        </button>
      </div>

      {/* Map Section */}
      <div className="relative rounded-lg shadow-md overflow-hidden">
        <div ref={mapRef} style={{ width: "100%", height: "400px" }} />{" "}
        {/* Map container */}
        {/* Nearby Places Card */}
        <div className="absolute bottom-4 right-4 bg-blue-500 bg-opacity-10 backdrop-blur-lg p-3 rounded-lg shadow-lg">
          <ul className="space-y-2">
            {locations.map((location, idx) => (
              <li
                key={idx}
                className="flex items-center justify-between space-x-2 text-sm"
              >
                <div className="flex flex-row items-center">
                  <span className="text-blue-600 mr-1">{location.icon}</span>
                  <span>{location.name}</span>
                </div>
                <span className="text-gray-500 ml-auto">
                  {location.distance} km
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

// Example usage with nearby locations data
const locationsData = [
  {
    name: "Narsingi Bus Stand",
    coordinates: { lat: 17.385, lng: 78.4867 },
    distance: 2.1,
    icon: <FaBus />,
  },
  {
    name: "St. Paul Cathedral",
    coordinates: { lat: 17.384, lng: 78.482 },
    distance: 3.2,
    icon: <FaChurch />,
  },
  {
    name: "Teacher’s Colony Metro Station",
    coordinates: { lat: 17.389, lng: 78.479 },
    distance: 4.1,
    icon: <FaSubway />,
  },
  {
    name: "Guntur International Airport",
    coordinates: { lat: 16.548, lng: 80.805 },
    distance: 5.2,
    icon: <FaPlane />,
  },
];

// const mapCenter = { lat: 17.385, lng: 78.4867 };

// Function to parse map location string to an object
const parseMapLocation = (locationString) => {
  const [lat, lng] = locationString.split(",").map(Number);
  return { lat, lng };
};

export const LocalitySection = ({ property }) => {
  // Parse the map location from the property data
  const mapCenter = property?.mapLocation
    ? parseMapLocation(property.mapLocation)
    : { lat: 17.385, lng: 78.4867 }; // Fallback location if mapLocation is not provided

  return (
    <NearbyPlacesMap
      locations={locationsData} // Assuming additional locations are static or fetched separately
      mapCenter={mapCenter}
    />
  );
};

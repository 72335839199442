import React from "react";

export const LocalitiesSection = () => {
  const localities = [
    { name: "Rajajinagar", nests: 243, color: "bg-purple-500" },
    { name: "Church Street", nests: 243, color: "bg-orange-400" },
    { name: "Rajajinagar", nests: 243, color: "bg-pink-400" },
    // Add more localities as needed
  ];

  return (
    <div className="max-w-7xl mx-auto mt-28 md:mt-12 p-6 xl:p-0">
      <h2 className="text-2xl font-CaslonRegular text-gray-800 mb-6">
        Localities around Guntur
      </h2>
      <div className="flex space-x-4 overflow-x-auto">
        {localities.map((locality, index) => (
          <>
            <div
              key={index}
              className={`${locality.color} text-white rounded-lg p-3 w-60  flex flex-row items-center  justify-between shadow-lg`}
            >
              <div className=" flex flex-col">
                <div className="text-lg font-semibold">{locality.name}</div>
                <div className="text-sm">{locality.nests} nests</div>
              </div>
              <div className="flex justify-end">
                <svg
                  className="w-5 h-5 text-white"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M10 6l6 6-6 6" />
                </svg>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NavbarListing } from "./_components/navbar";
import { ListingHeroSection } from "./_components/heroSection";
import { ListingServices } from "./_components/services";
import { LocalitySection } from "./_components/map";
import { AvailableRooms } from "./_components/rooms";
import Reviews from "./_components/reviews";
import MobileAppSection from "./_components/mobile-section";
import Footer from "./_components/Footer";
import { GetPropertyDetail } from "../../actions/get-property-by-id";

const ListingDetailPage = () => {
  const { id } = useParams();
  const [properties, setProperties] = useState(null);
  console.log("🚀 ~ Specific Property ----- >", properties);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const allProperties = await GetPropertyDetail(id);
        setProperties(allProperties.data);
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      }
    };

    fetchProperties();
  }, [id]);
  console.log("🚀 ~ ListingDetailPage ~ id:", id);

  return (
    <>
      <div className=" w-full">
        <NavbarListing />
        <ListingHeroSection property={properties} />
        <div className=" max-w-7xl mx-auto">
          <ListingServices property={properties} />
          <LocalitySection property={properties} />
          <AvailableRooms property={properties} />
          <Reviews />
          <MobileAppSection />
        </div>
        <Footer />
        {/* Footer Bottom Text */}
        <div className=" max-w-7xl mx-auto flex flex-row items-center justify-center">
          <p className="text-gray-500 text-sm mb-6">
            &copy; 2024 © Alra AI LLP
          </p>
        </div>
      </div>
    </>
  );
};

export default ListingDetailPage;

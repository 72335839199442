import React from "react";
import { FaInstagram, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { MdPhoneIphone } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <div className="bg-blue-50 pt-12 pb-6 px-6 rounded-3xl relative overflow-hidden max-w-7xl mx-auto mb-10 mt-40">
        {/* Background gradient and shapes */}
        <div className="absolute bottom-3 flex justify-center items-end w-full ">
          <img
            src="/hero-section.png" // Replace with the actual path to the shapes image
            alt="Decorative Background"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="absolute inset-x-0 bottom-0 h-48 bg-gradient-to-b from-transparent to-blue-400 rounded-t-3xl"></div>

        <div className="relative max-w-4xl mx-auto text-center z-10">
          {/* Logo and Description */}
          <div className="flex flex-col items-center space-y-2">
            <div className=" flex flex-row items-center space-x-3">
              <img src="/logo.png" alt="Logo" className="h-6 w-6" />{" "}
              {/* Replace with actual logo source */}
              <h2 className="text-xl font-semibold text-gray-800">
                supernest.ai
              </h2>
            </div>
            <p className="text-gray-600 max-w-sm">
              Find your perfect thriving, lively place to call home. Browse
              hundreds of dorms, hostels, and rental properties ready to move
              in.
            </p>
          </div>

          {/* Social Icons */}
          <div className="flex justify-center space-x-4 mt-4">
            <a href="/" className="text-gray-500 hover:text-gray-700">
              <FaInstagram size={20} />
            </a>
            <a href="/" className="text-gray-500 hover:text-gray-700">
              <FaWhatsapp size={20} />
            </a>
            <a href="/" className="text-gray-500 hover:text-gray-700">
              <FaEnvelope size={20} />
            </a>
          </div>

          {/* Contact Info */}
          <div className="mt-6 text-gray-700 space-y-2">
            <div className="flex justify-center items-center space-x-2">
              <FiPhone size={20} />
              <span>+91 98765 43210</span>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <FaEnvelope size={20} />
              <span>info@supernest.ai</span>
            </div>
            <p className="text-sm text-gray-600 max-w-xs mx-auto mt-2">
              XYZ Towers, Plot No. 42, 5th Cross Opposite Some Landmark Sector 3
              Bangalore - 5600XX Karnataka, India
            </p>
          </div>

          {/* Request Access */}
          <div className="mt-8 flex justify-center items-center">
            <div className="relative">
              <MdPhoneIphone className="absolute left-3 top-3 " size={20} />
              <input
                type="text"
                placeholder="Enter your phone number..."
                className="w-full max-w-xs bg-white border border-gray-300 rounded-lg py-2 pl-10 pr-4 text-gray-600 placeholder-gray-400 focus:outline-none focus:border-blue-500"
              />
            </div>
            <button className="bg-blue-600 text-white rounded-full py-2 px-6 ml-2 hover:bg-blue-700">
              Request Early Access
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React from "react";
import { FaGlobe } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";

const Footer = () => {
  return (
    <footer className="w-full border-t border-gray-200 py-4 px-6 flex items-center justify-between text-sm text-gray-600">
      {/* Left Side - Copyright and Links */}
      <div className="flex items-center space-x-4">
        <span>© 2024 ALRA AI LLP</span>
        <span className="cursor-pointer hover:underline">Privacy</span>
        <span className="cursor-pointer hover:underline">Terms</span>
      </div>

      {/* Right Side - Language, Currency, and Support */}
      <div className="flex items-center space-x-4">
        {/* Language Selector */}
        <div className="flex items-center space-x-1 cursor-pointer hover:text-gray-800">
          <FaGlobe size={14} />
          <span>English (CA)</span>
        </div>

        {/* Currency Selector */}
        <div className="flex items-center space-x-1 cursor-pointer hover:text-gray-800">
          <span>$</span>
          <span>CAD</span>
        </div>

        {/* Support and Resources Dropdown */}
        <div className="flex items-center space-x-1 cursor-pointer hover:text-gray-800">
          <span>Support & resources</span>
          <FiChevronDown size={14} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

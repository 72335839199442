import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import PropertyForm from "../../components/property-fom";
import PropertyImages from "../../components/property-images";
import RoomDetailsForm from "../../components/room-detail";
import { createProperty } from "../../services/api";

const CreateProperty = () => {
  // Centralized state to gather all data
  const [propertyData, setPropertyData] = useState({
    name: "",
    city: "",
    services: [],
    completeAddress: {
      buildingName: "",
      streetName: "",
      locality: "",
    },
    ownerDetails: {
      name: "",
      phone: "",
    },
    referralDetails: {
      name: "",
      phone: "",
    },
    location: {
      lat: null,
      lng: null,
    },
    images: [],
    roomDetails: [],
  });

  // State to track validation errors
  const [errors, setErrors] = useState({});

  // Function to validate the property form data
  const validatePropertyData = () => {
    const newErrors = {};

    // Validate Property Details
    if (!propertyData.name) newErrors.name = "Property name is required.";
    if (!propertyData.city) newErrors.city = "City is required.";
    if (propertyData.services.length === 0)
      newErrors.services = "At least one service must be selected.";
    if (!propertyData.completeAddress.buildingName)
      newErrors.buildingName = "Building name is required.";
    if (!propertyData.completeAddress.streetName)
      newErrors.streetName = "Street name is required.";
    if (!propertyData.completeAddress.locality)
      newErrors.locality = "Locality is required.";
    if (!propertyData.location.lat || !propertyData.location.lng)
      newErrors.location = "Property location is required.";

    // Validate Property Images
    if (propertyData.images.length < 3)
      newErrors.images = "Please upload at least 3 images.";
    if (!propertyData.ownerDetails.name)
      newErrors.ownerName = "Owner's name is required.";
    if (!propertyData.ownerDetails.phone)
      newErrors.ownerPhone = "Owner's phone number is required.";
    if (!/^\d{10}$/.test(propertyData.ownerDetails.phone))
      newErrors.ownerPhone = "Owner's phone number must be 10 digits.";

    // Validate Room Details
    if (propertyData.roomDetails.length === 0)
      newErrors.roomDetails = "Please add at least one room type.";
    propertyData.roomDetails.forEach((room, index) => {
      if (!room.typeName)
        newErrors[
          `roomTypeName${index}`
        ] = `Room type name is required for room ${index + 1}.`;
      if (!room.monthlyRent)
        newErrors[
          `roomMonthlyRent${index}`
        ] = `Monthly rent is required for room ${index + 1}.`;
      if (!room.securityDeposit)
        newErrors[
          `roomSecurityDeposit${index}`
        ] = `Security deposit is required for room ${index + 1}.`;
      if (room.selectedPhotos.length < 3)
        newErrors[
          `roomPhotos${index}`
        ] = `Please upload at least 3 photos for room ${index + 1}.`;
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Function to handle the final submission
  const handleSubmit = async () => {
    if (!validatePropertyData()) {
      toast.error("Please fill all required fields correctly.");
      console.error("Validation errors:", errors);
      return;
    }

    try {
      const response = await createProperty(propertyData);
      if (response.success) {
        toast.success("Property submitted successfully!");
      } else {
        toast.error(`Error: ${response.message}`);
      }
    } catch (error) {
      console.error("Error submitting property:", error);
      toast.error("An error occurred while submitting the property.");
    }
  };

  // Handlers to update the centralized state from child components
  const updatePropertyFormData = (data) => {
    setPropertyData((prev) => ({
      ...prev,
      name: data.name,
      city: data.city,
      services: data.services,
      completeAddress: data.completeAddress,
      location: data.location,
    }));
  };

  const updatePropertyImagesData = (images, ownerDetails, referralDetails) => {
    setPropertyData((prev) => ({
      ...prev,
      images: images,
      ownerDetails: ownerDetails,
      referralDetails: referralDetails,
    }));
  };

  const updateRoomDetailsData = (rooms) => {
    setPropertyData((prev) => ({
      ...prev,
      roomDetails: rooms,
    }));
  };
  return (
    <div className="space-y-2">
      <ToastContainer />
      <PropertyForm updateFormData={updatePropertyFormData} />
      <PropertyImages updateImagesData={updatePropertyImagesData} />
      <RoomDetailsForm updateRoomsData={updateRoomDetailsData} />

      {/* Display validation errors */}
      {Object.keys(errors).length > 0 && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <ul>
            {Object.keys(errors).map((key) => (
              <li key={key}>{errors[key]}</li>
            ))}
          </ul>
        </div>
      )}

      <div className="w-full flex flex-row items-center justify-center my-6">
        <button
          onClick={() => handleSubmit()}
          className="bg-[#1F70F5] text-white rounded-full px-10 py-3"
        >
          Submit Property
        </button>
      </div>
    </div>
  );
};

export default CreateProperty;

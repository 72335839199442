import React, { useState } from "react";
import { FaHeart, FaUser } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiMenu, HiX } from "react-icons/hi";
import LoginDialog from "../../../components/login"; // Import the LoginDialog component
import VerifyOtpDialog from "../../../components/verify-otp";
import LoginEmailDialog from "../../../components/login-email";

export const NavbarHome = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false); // State to control login dialog
  const [isVerifyOtpOpen, setIsVerifyOtpOpen] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  const handleGetOtp = () => {
    setIsLoginOpen(false);
    setIsVerifyOtpOpen(true);
  };

  const handleGetEmail = () => {
    setIsVerifyOtpOpen(false);
    setIsEmail(true);
  };

  return (
    <div className="relative flex items-center justify-between p-4 md:p-8 max-w-7xl mx-auto border-b-2 border-gray-200">
      {/* Logo Section */}
      <div
        onClick={() => (window.location.href = `/`)}
        className="flex items-center space-x-2 hover:cursor-pointer"
      >
        <img src="/logo.png" alt="Logo" className="h-6 w-6 md:h-8 md:w-8" />
        <h2 className="text-lg md:text-xl">
          <span className="font-bold">supernest</span>.ai
        </h2>
      </div>

      {/* Desktop Navigation */}
      <div className="hidden md:flex items-center space-x-6">
        <FaHeart size={20} className="text-gray-400" />

        <button className="bg-[#1F70F5] rounded-full flex items-center text-white px-4 py-2 space-x-2 hover:bg-opacity-80 transition duration-300">
          <IoIosCheckmarkCircleOutline size={20} />
          <p>Nest your Property</p>
        </button>

        {/* Login Button triggers LoginDialog */}
        <div
          className="flex items-center bg-[#7da9f0] text-blue-600 bg-opacity-50 py-2 px-4 rounded-full space-x-3 cursor-pointer"
          onClick={() => setIsLoginOpen(true)}
        >
          <p>Login</p>
          <FaUser />
        </div>
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden flex items-center">
        <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          <HiMenu size={28} className="text-gray-600" />
        </button>
      </div>

      {/* Mobile Menu with Slide-in Effect */}
      <div
        className={`fixed inset-y-0 left-0 w-3/4 max-w-xs bg-white shadow-lg z-20 transform transition-transform duration-300 ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col items-start space-y-4 p-6">
          <button
            onClick={() => setIsMobileMenuOpen(false)}
            className="self-end mb-4 text-gray-600"
          >
            <HiX size={24} />
          </button>

          <FaHeart size={20} className="text-gray-400" />

          <button className="bg-[#1F70F5] rounded-full flex items-center text-white px-4 space-x-2 hover:bg-opacity-80 transition duration-300 ease-in-out">
            <IoIosCheckmarkCircleOutline size={19} />
            <p>Nest your Property</p>
          </button>

          {/* Mobile Login Button */}
          <div
            className="flex items-center bg-[#2B78F5] bg-opacity-50 py-2 px-4 rounded-full space-x-3 cursor-pointer"
            onClick={() => setIsLoginOpen(true)}
          >
            <p>Login</p>
            <FaUser />
          </div>
        </div>
      </div>

      {/* Overlay for mobile menu */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10"
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      )}

      {/* Login Dialog */}
      <LoginDialog
        isOpen={isLoginOpen}
        onClose={() => setIsLoginOpen(false)}
        onGetOtp={handleGetOtp}
      />
      <VerifyOtpDialog
        isOpen={isVerifyOtpOpen}
        onClose={() => setIsVerifyOtpOpen(false)}
        getemail={handleGetEmail}
      />

      <LoginEmailDialog isOpen={isEmail} onClose={() => setIsEmail(false)} />
    </div>
  );
};

// utils/fetchGeocode.js
export async function fetchGeocode(address) {
  const apiKey = "AIzaSyCceJ_PFXKkrZC0vkJo1GPL1dD2IeB4NPI"; // Replace with your actual API key
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === "OK") {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    } else {
      console.error("Geocode error:", data.status);
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch geocode:", error);
    return null;
  }
}

/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Slider from "react-slick";
import { FiShield } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BiMaleFemale } from "react-icons/bi";
import { FaBed, FaCheckCircle } from "react-icons/fa";

const CustomPrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white bg-opacity-15 backdrop-blur-lg p-2 rounded-full shadow-lg z-50"
  >
    <IoIosArrowBack size={20} className="text-gray-800" />
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white bg-opacity-15 backdrop-blur-lg p-2 rounded-full shadow-lg"
  >
    <IoIosArrowForward size={20} className=" text-gray-800" />
  </button>
);

const RoomCard = ({ room }) => {
  // Slider settings for react-slick with custom arrows
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="flex bg-white p-4 rounded-lg space-x-4  relative ">
      {/* Room Image Slider with Badges */}
      <div className="relative w-96 h-40 rounded-lg overflow-hidden">
        <Slider {...settings}>
          {room.images.map((image, index) => (
            <div key={index} className="h-40">
              <img
                src={image}
                alt={`${room.name} image ${index + 1}`}
                className={`w-full h-full object-cover rounded-lg ${
                  !room.available ? "grayscale" : ""
                }`}
              />
            </div>
          ))}
        </Slider>

        {/* Room Badges */}
        <div className="absolute top-2 left-2 flex flex-row space-x-2 ">
          {room.recommended && (
            <span className="bg-black text-yellow-400 text-xs font-semibold px-2 py-1 rounded-lg">
              ⭐ RECOMMENDED
            </span>
          )}
          <span
            className={`text-xs font-semibold px-2 py-1 rounded-lg ${
              room.available
                ? "bg-blue-500 text-white"
                : "bg-gray-500 text-white"
            }`}
          >
            {room.available ? (
              <span className=" flex flex-row items-center">
                {" "}
                <FaCheckCircle className=" mr-1" /> Available
              </span>
            ) : (
              "Not Available"
            )}
          </span>
        </div>

        {room.topRated && (
          <span className="absolute top-2 left-24 bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
            🔹 Top Rated
          </span>
        )}

        <span className="bg-white backdrop-blur-lg bg-opacity-15 rounded-xl px-2 py-1 absolute bottom-2 left-2 text-sm text-white flex items-center space-x-1">
          <BiMaleFemale className="text-white" />
          <span>Unisex</span>
        </span>
        <span className="bg-white backdrop-blur-lg bg-opacity-15 rounded-xl px-2 py-1 absolute bottom-2 left-24 text-sm text-white flex items-center space-x-1">
          <FaBed className="text-white" />
          <span>{room.type}</span>
        </span>
      </div>

      {/* Room Details */}
      <div className="flex flex-col justify-between w-full">
        <div>
          <h3 className="text-lg font-semibold text-gray-800">{room.name}</h3>
          <p className="text-sm text-gray-500">{room.type}</p>
          <div className="flex items-center justify-between space-x-2 mt-2">
            <span className=" font-bold text-white rounded-lg p-1.5 bg-black">
              {room.price}
            </span>
            <span className=" text-gray-500 text-sm">
              {/* {room.discountedPrice} */}
              {room.securityDeposit} Security Deposit
            </span>
          </div>
          <div className="text-sm text-gray-500 mt-1 flex items-center">
            {/* <FiShield className="inline mr-1" /> {room.securityDeposit} Security
            Deposit */}
            <span className="line-through text-gray-500 text-sm">
              <FiShield className="inline mr-1" />
              {room.discountedPrice}
            </span>
          </div>
        </div>

        {/* Amenities */}
        <div className="flex flex-wrap gap-2 mt-2">
          {room.amenities.slice(0, 4).map((amenity, idx) => (
            <span
              key={idx}
              className="bg-gray-100 text-gray-700 text-xs font-semibold  px-5 py-2 rounded-full"
            >
              {amenity}
            </span>
          ))}
          {room.amenities.length > 4 && (
            <span className="bg-gray-100 text-gray-700 text-xs font-semibold px-3 py-2 rounded-full">
              + {room.amenities.length - 4} more
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

// Example Usage with Multiple Images per Room
const roomsData = [
  {
    name: "Quadruple Premier Room",
    type: "Quadruple",
    price: "₹21,394/month",
    discountedPrice: "₹31,952/month",
    securityDeposit: "₹15,000",
    amenities: [
      "AC",
      "Attached Washroom",
      "High Speed Wifi",
      "Laundry Service",
      "Storage Shelf",
    ],
    images: ["/property.jpeg", "/dummy.png", "/property.jpeg"], // Array of images for slider
    available: true,
    recommended: true,
    topRated: false,
  },
  {
    name: "Single Premier Room",
    type: "Single",
    price: "₹42,109/month",
    discountedPrice: "₹45,234/month",
    securityDeposit: "₹30,000",
    amenities: [
      "AC",
      "Attached Washroom",
      "High Speed Wifi",
      "Laundry Service",
      "Storage Shelf",
    ],
    images: ["/property.jpeg", "/property.jpeg"], // Array of images for slider
    available: true,
    recommended: false,
    topRated: true,
  },
  {
    name: "Dorm Room",
    type: "8-Person Dorm",
    price: "₹12,109/month",
    discountedPrice: "₹25,234/month",
    securityDeposit: "₹5,000",
    amenities: [
      "AC",
      "Attached Washroom",
      "High Speed Wifi",
      "Laundry Service",
      "Storage Shelf",
    ],
    images: ["/dummy.png"], // Single image without slider
    available: false,
    recommended: false,
    topRated: false,
  },
];

export const AvailableRooms = ({ property }) => {
  if (property?.availableRooms?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <h2 className="text-lg font-semibold text-gray-700 mb-4">
          No Rooms Available
        </h2>
      </div>
    );
  }
  return (
    <>
      <h2 className="text-lg font-semibold text-gray-700 mb-4">
        Available Rooms
      </h2>
      <div className="max-w-[830px] space-y-4 border border-gray-100 rounded-2xl shadow-xl mb-7">
        {roomsData.map((room, index) => (
          <RoomCard key={index} room={room} />
        ))}
      </div>
    </>
  );
};

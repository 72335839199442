import React from "react";

const MobileAppSection = () => {
  return (
    <>
      <h2 className="text-lg font-semibold text-gray-600 mt-6">Mobile App</h2>
      <div className="max-w-[830px]   p-6 bg-white rounded-xl shadow-xl mb-6">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8">
          {/* Mobile App Mockup */}
          <div className="w-full md:w-1/2 flex justify-center">
            <div className="bg-gray-100 rounded-lg overflow-hidden p-4 w-full max-w-xs max-h-[300px]">
              <img
                src="/mobile.png" // Replace with actual image source
                alt="Mobile App Mockup"
                className="w-full object-cover"
                style={{ clipPath: "inset(0 0 50% 0)" }} // Show only the top half
              />
            </div>
          </div>

          {/* Text Content */}
          <div className="w-full md:w-1/2 space-y-7">
            <div className="flex items-center">
              <div className="h-1 w-12 bg-black rounded-full "></div>
              <div className="flex-grow h-[1px] bg-gray-300 "></div>
            </div>
            <h3 className="text-lg font-semibold text-gray-800">SuperSearch</h3>

            <p className="text-xs uppercase font-CaslonRegular text-gray-400 tracking-wide">
              Exclusively for SuperNest
            </p>
            <h4 className="text-2xl font-CaslonRegular text-gray-800 leading-snug">
              Filter properties based on commute duration
            </h4>
            <p className="text-gray-600 text-sm leading-relaxed">
              Find your perfect thriving, lively place to call home. Browse
              hundreds of dorms, hostels, and rental properties ready to move
              in.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileAppSection;

import React, { useState } from "react";
import { MdOutlineMap } from "react-icons/md";
import Slider from "react-slider"; // Import the react-slider library
import { FaCheckCircle } from "react-icons/fa";

const Filters = ({ onApplyFilters }) => {
  const [locality, setLocality] = useState("");
  const [priceRange, setPriceRange] = useState([6199, 48799]);
  const [type, setType] = useState("Monthly"); // Default selection for Type
  const [gender, setGender] = useState("Female"); // Default selection for Gender
  const [occupancy, setOccupancy] = useState("Double"); // Default selection for Occupancy
  const [amenities, setAmenities] = useState([]); // Multiple selection for Amenities
  const [services, setServices] = useState([]); // Multiple selection for Services

  const handlePriceChange = (values) => {
    setPriceRange(values);
  };

  const handleAmenitiesToggle = (amenity) => {
    setAmenities((prev) =>
      prev.includes(amenity)
        ? prev.filter((item) => item !== amenity)
        : [...prev, amenity]
    );
  };

  const handleServicesToggle = (service) => {
    setServices((prev) =>
      prev.includes(service)
        ? prev.filter((item) => item !== service)
        : [...prev, service]
    );
  };

  const resetFilters = () => {
    setLocality("");
    setPriceRange([6199, 48799]);
    setType("Monthly");
    setGender("Female");
    setOccupancy("Double");
    setAmenities([]);
    setServices([]);
  };

  const applyFilters = () => {
    const filters = {
      locality,
      priceRange,
      type,
      gender,
      occupancy,
      amenities,
      services,
    };
    onApplyFilters(filters); // Pass filters to parent component
  };

  return (
    <div className="max-w-xs p-6 bg-white rounded-2xl shadow-xl">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-CaslonRegular">Filters</h2>
        <button className="bg-blue-600 text-white px-4 py-2 rounded-full flex items-center space-x-1">
          <span>Show map</span>
          <MdOutlineMap size={20} />
        </button>
      </div>

      {/* Locality */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Locality
        </label>
        <input
          type="text"
          placeholder="Start typing..."
          value={locality}
          onChange={(e) => setLocality(e.target.value)}
          className="w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>

      {/* Price Range with Slider */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Price Range
        </label>
        <Slider
          className="w-full h-3 bg-gray-200 rounded-lg"
          thumbClassName="w-3 h-3 bg-blue-600 rounded-full focus:outline-none"
          trackClassName="bg-blue-600 rounded-lg"
          min={0}
          max={50000}
          step={500}
          value={priceRange}
          onChange={handlePriceChange}
        />
        <div className="flex justify-between mt-2">
          <input
            type="number"
            value={priceRange[0]}
            onChange={(e) => setPriceRange([+e.target.value, priceRange[1]])}
            className="w-1/2 px-2 py-1 border border-gray-300 rounded-lg focus:outline-none"
          />
          <span className="mx-2">to</span>
          <input
            type="number"
            value={priceRange[1]}
            onChange={(e) => setPriceRange([priceRange[0], +e.target.value])}
            className="w-1/2 px-2 py-1 border border-gray-300 rounded-lg focus:outline-none"
          />
        </div>
      </div>

      {/* Type */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Type
        </label>
        <div className="flex space-x-2">
          {["Day", "Monthly"].map((option) => (
            <button
              key={option}
              onClick={() => setType(option)}
              className={`px-4 py-2 border rounded-full ${
                type === option
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600 hover:bg-blue-100"
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      {/* Gender */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Gender
        </label>
        <div className="flex space-x-2">
          {["Male", "Female", "Unisex"].map((option) => (
            <button
              key={option}
              onClick={() => setGender(option)}
              className={`px-4 py-2 border rounded-full ${
                gender === option
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600 hover:bg-blue-100"
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      {/* Occupancy */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Occupancy
        </label>
        <div className="flex flex-wrap gap-2">
          {["Single", "Double", "Triple", "Quadruple", "Quintuple", ">5"].map(
            (option) => (
              <button
                key={option}
                onClick={() => setOccupancy(option)}
                className={`px-4 py-2 border rounded-full ${
                  occupancy === option
                    ? "bg-blue-100 text-blue-600"
                    : "text-gray-600 hover:bg-blue-100"
                }`}
              >
                {option}
              </button>
            )
          )}
        </div>
      </div>

      {/* Amenities */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Amenities
        </label>
        <div className="flex flex-wrap gap-2">
          {[
            "Attached Bathroom",
            "AC",
            "Attached Balcony",
            "Storage Shelf",
            "Desert Cooler",
            "Kitchen",
          ].map((amenity) => (
            <button
              key={amenity}
              onClick={() => handleAmenitiesToggle(amenity)}
              className={`px-4 py-2 border rounded-full ${
                amenities.includes(amenity)
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600 hover:bg-blue-100"
              }`}
            >
              {amenity}
            </button>
          ))}
        </div>
      </div>

      {/* Services */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Services
        </label>
        <div className="flex flex-wrap gap-2">
          {[
            "Meal Subscription",
            "High Speed Wifi",
            "Laundry",
            "Power Backup",
            "Housekeeping",
            "Gym",
            "Washing Machine",
            "Hot Water",
            "Television",
            "Water Purifier",
            "24x7 Surveillance",
          ].map((service) => (
            <button
              key={service}
              onClick={() => handleServicesToggle(service)}
              className={`px-4 py-2 border rounded-full ${
                services.includes(service)
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600 hover:bg-blue-100"
              }`}
            >
              {service}
            </button>
          ))}
        </div>
      </div>

      {/* Apply and Reset Buttons */}
      <div className="flex justify-between mt-4 space-x-2">
        <button
          onClick={applyFilters}
          className="flex items-center justify-center px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 w-full"
        >
          <FaCheckCircle className=" mr-1" /> Apply
        </button>
        <button
          onClick={resetFilters}
          className="flex items-center px-6 py-2 bg-blue-100 text-blue-600 rounded-full hover:bg-blue-200"
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default Filters;

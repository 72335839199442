import React, { useEffect, useState } from "react";
import Filters from "./_components/filters";
import MapWithPricing from "./_components/search-map";
import { PropertyCardListView } from "../home/_components/properties";
import Footer from "./_components/footer";
import { SearchNavbar } from "./_components/navbar-search";
import { fetchProperties } from "../../actions/get-search";

const SearchPage = () => {
  const [properties, setProperties] = useState([]);
  console.log("🚀 ~ SearchPage ~ properties:", properties);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [, setSearchQuery] = useState("");

  // Fetch properties with or without filters
  const applyFilters = async (filters) => {
    setLoading(true);
    try {
      const result = await fetchProperties(filters);
      if (result.success) {
        const data = Array.isArray(result.data) ? result.data : []; // Ensure data is an array
        setProperties(data);
        setFilteredProperties(data);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError("Failed to load properties");
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch without filters
  useEffect(() => {
    applyFilters({});
  }, []);

  // Handle direct search input from the SearchNavbar
  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query) {
      setFilteredProperties(properties); // Reset to full list if query is empty
    } else {
      const filtered = properties.filter(
        (property) =>
          property.title.toLowerCase().includes(query.toLowerCase()) ||
          property.location.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProperties(filtered);
    }
  };

  return (
    <div className="w-full">
      {/* Pass handleSearch to SearchNavbar to allow direct search */}
      <SearchNavbar onSearch={handleSearch} loading={loading} />
      <div className="flex flex-row max-w-7xl mx-auto space-x-4 mb-20">
        <Filters onApplyFilters={applyFilters} />
        <div className="flex-1">
          <MapWithPricing properties={filteredProperties} />

          {loading ? (
            <div className="text-center my-10">Loading properties...</div>
          ) : error ? (
            <div className="text-center my-10 text-red-600">{error}</div>
          ) : filteredProperties.length === 0 ? (
            <div className="text-center my-10">No properties found</div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
              {filteredProperties?.map((property, index) => (
                <PropertyCardListView key={index} property={property} />
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchPage;

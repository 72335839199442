import React, { useEffect, useState } from "react";
import RoomTypeCard from "./room-type";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const occupancyBedCount = {
  Single: 1,
  Double: 2,
  Triple: 3,
  Quadruple: 4,
  Quintuple: 5,
  ">5": 6,
};

const availableAmenities = [
  "Attached Bathroom",
  "AC",
  "Attached Balcony",
  "Storage Shelf",
  "Desert Cooler",
  "Kitchen",
  "Gym",
  "Parking",
  "Swimming Pool",
  "WiFi",
];

const RoomDetailsForm = ({ updateRoomsData }) => {
  const [forms, setForms] = useState([createFormState()]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [errors, setErrors] = useState({});

  // Function to create a new form state
  function createFormState() {
    return {
      id: Date.now(),
      typeName: "",
      monthlyRent: "",
      securityDeposit: "",
      selectedGender: "Female",
      selectedOccupancy: "Double",
      selectedPhotos: [],
      selectedAmenities: [],
      beds: 2,
      rooms: 0,
    };
  }

  // Update parent component with room details data
  useEffect(() => {
    updateRoomsData(forms);
  }, [forms, updateRoomsData]);

  // Handle form data updates
  const handleFormUpdate = (id, field, value) => {
    setForms((prevForms) =>
      prevForms?.map((form) =>
        form.id === id ? { ...form, [field]: value } : form
      )
    );
  };

  // Handle occupancy selection and update bed count
  const handleOccupancySelect = (id, occupancy) => {
    const bedCount = occupancyBedCount[occupancy] || 0;
    handleFormUpdate(id, "selectedOccupancy", occupancy);
    handleFormUpdate(id, "beds", bedCount);
  };

  // Handle gender selection
  const handleGenderSelect = (id, gender) => {
    handleFormUpdate(id, "selectedGender", gender);
  };

  // Function to handle image upload
  const handleImageUpload = (id, event) => {
    const files = Array.from(event.target.files);

    // Function to read file as Base64
    const readFileAsBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => reject(new Error("Error reading file"));
        reader.readAsDataURL(file);
      });
    };

    // Convert each file to a Base64 string
    Promise.all(files.map(readFileAsBase64))
      .then((base64Strings) => {
        setForms((prevForms) =>
          prevForms.map((form) =>
            form.id === id
              ? {
                  ...form,
                  selectedPhotos: [...form.selectedPhotos, ...base64Strings],
                }
              : form
          )
        );
      })
      .catch((error) => {
        toast.error("Error converting images to Base64.");
        console.error("Error converting images to Base64:", error);
      });
  };

  // Remove a photo
  const removePhoto = (id, index) => {
    setForms((prevForms) =>
      prevForms.map((form) =>
        form.id === id
          ? {
              ...form,
              selectedPhotos: form.selectedPhotos.filter((_, i) => i !== index),
            }
          : form
      )
    );
  };

  // Toggle amenity selection
  const toggleAmenity = (id, amenity) => {
    setForms((prevForms) =>
      prevForms.map((form) =>
        form.id === id
          ? {
              ...form,
              selectedAmenities: form.selectedAmenities.includes(amenity)
                ? form.selectedAmenities.filter((a) => a !== amenity)
                : [...form.selectedAmenities, amenity],
            }
          : form
      )
    );
  };

  // Validation Logic
  const validateForm = (form) => {
    const newErrors = {};
    if (!form.typeName) newErrors.typeName = "Type name is required.";
    if (!form.monthlyRent) newErrors.monthlyRent = "Monthly rent is required.";
    if (!form.securityDeposit)
      newErrors.securityDeposit = "Security deposit is required.";
    if (form.selectedPhotos.length < 3)
      newErrors.selectedPhotos = "Please upload at least 3 photos.";
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Submit form and add room type
  const submitForm = (form) => {
    if (validateForm(form)) {
      setRoomTypes((prevRoomTypes) => [...prevRoomTypes, form]);
      toast.success("Room type added successfully!");
    } else {
      toast?.error("Please fill all the required fields.");
    }
  };

  // Duplicate the form
  const duplicateForm = () => {
    setForms((prevForms) => [...prevForms, createFormState()]);
  };

  // Delete a specific form
  const deleteForm = (id) => {
    setForms((prevForms) => prevForms?.filter((form) => form.id !== id));
  };

  return (
    <div className="bg-white flex flex-col justify-center">
      <ToastContainer />
      {/* Render RoomTypeCard for each submitted form */}
      {roomTypes.map((room, index) => (
        <RoomTypeCard key={index} room={room} />
      ))}

      {forms.map((form) => (
        <div key={form.id} className="w-full mb-8 p-12 relative">
          <h2 className="text-lg font-medium mb-4 text-gray-800">
            Room Details
          </h2>

          {/* Icons for submit and delete */}
          <div className="absolute top-12 right-12 flex space-x-2">
            <button
              onClick={() => deleteForm(form.id)}
              className="bg-red-50 p-2 rounded-full hover:bg-red-100 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6 text-red-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <button
              onClick={() => submitForm(form)}
              className="bg-green-50 p-2 rounded-full hover:bg-green-100 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6 text-green-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </button>
          </div>

          <div className="border shadow-md rounded-2xl p-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {/* Room Details Form */}
              <div className="border rounded-lg p-6 bg-gray-50">
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Type Name
                  </label>
                  <input
                    type="text"
                    value={form.typeName}
                    onChange={(e) =>
                      handleFormUpdate(form.id, "typeName", e.target.value)
                    }
                    placeholder="Enter room type"
                    className={`w-full rounded-lg border ${
                      errors.typeName ? "border-red-500" : "border-gray-300"
                    } p-3 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {errors.typeName && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.typeName}
                    </p>
                  )}
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Monthly Rent
                  </label>
                  <input
                    type="text"
                    value={form.monthlyRent}
                    onChange={(e) =>
                      handleFormUpdate(form.id, "monthlyRent", e.target.value)
                    }
                    placeholder="₹ 12,999"
                    className={`w-full rounded-lg border ${
                      errors.monthlyRent ? "border-red-500" : "border-gray-300"
                    } p-3 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {errors.monthlyRent && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.monthlyRent}
                    </p>
                  )}
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Gender
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {["Male", "Female", "Both"].map((gender) => (
                      <button
                        key={gender}
                        onClick={() => handleGenderSelect(form.id, gender)}
                        className={`px-4 py-2 rounded-full border ${
                          form.selectedGender === gender
                            ? "bg-blue-100 text-blue-600"
                            : "bg-gray-100 text-gray-600"
                        } focus:outline-none`}
                      >
                        {gender}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Amenities
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {availableAmenities.map((amenity) => (
                      <button
                        key={amenity}
                        onClick={() => toggleAmenity(form.id, amenity)}
                        className={`px-3 py-1 rounded-full border ${
                          form.selectedAmenities.includes(amenity)
                            ? "bg-green-100 text-green-600"
                            : "bg-gray-100 text-gray-600"
                        } focus:outline-none`}
                      >
                        {amenity}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Security Deposit, Occupancy, and Photos */}
              <div className="flex flex-col space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Security Deposit
                  </label>
                  <input
                    type="text"
                    value={form.securityDeposit}
                    onChange={(e) =>
                      handleFormUpdate(
                        form.id,
                        "securityDeposit",
                        e.target.value
                      )
                    }
                    placeholder="₹ 33,000"
                    className={`w-full rounded-lg border ${
                      errors.securityDeposit
                        ? "border-red-500"
                        : "border-gray-300"
                    } p-3 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {errors.securityDeposit && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.securityDeposit}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Occupancy
                  </label>
                  <div className="flex gap-2 flex-wrap">
                    {[
                      "Single",
                      "Double",
                      "Triple",
                      "Quadruple",
                      "Quintuple",
                      ">5",
                    ].map((occupancy) => (
                      <button
                        key={occupancy}
                        onClick={() =>
                          handleOccupancySelect(form.id, occupancy)
                        }
                        className={`px-4 py-2 rounded-full border ${
                          form.selectedOccupancy === occupancy
                            ? "bg-blue-100 text-blue-600"
                            : "bg-gray-100 text-gray-600"
                        } focus:outline-none`}
                      >
                        {occupancy}
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Photos
                  </label>
                  <div className="flex gap-2 mb-4">
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => handleImageUpload(form.id, e)}
                      className="hidden"
                      id={`upload-photos-${form.id}`}
                    />
                    <label
                      htmlFor={`upload-photos-${form.id}`}
                      className="cursor-pointer bg-blue-100 text-blue-600 text-sm font-medium px-4 py-2 rounded-full border border-dashed border-blue-300"
                    >
                      Upload Photos
                    </label>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                    {form.selectedPhotos.map((photo, index) => (
                      <div key={index} className="relative">
                        <img
                          src={photo}
                          alt="Room"
                          className="rounded-lg w-full h-32 object-cover"
                        />
                        <button
                          onClick={() => removePhoto(form.id, index)}
                          className="absolute top-1 right-1 bg-gray-700 text-white rounded-full p-1"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                  {errors.selectedPhotos && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.selectedPhotos}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Form Button */}
          <div className="flex justify-center mt-6">
            <button
              onClick={() => submitForm(form)}
              className="text-blue-600 font-medium px-6 py-2 rounded-full bg-blue-50 hover:bg-blue-100"
            >
              + Add new type
            </button>
          </div>
        </div>
      ))}

      {/* Add New Form Button */}
      {!forms.length && (
        <div className="flex justify-center mt-6">
          <button
            onClick={duplicateForm}
            className="text-blue-600 font-medium px-6 py-2 rounded-full bg-blue-50 hover:bg-blue-100"
          >
            + Add new type
          </button>
        </div>
      )}
    </div>
  );
};

export default RoomDetailsForm;

/**
 * Get Property Details by id
 */
export async function GetPropertyDetail(id) {
  try {
    if (!id) return { error: "id is required" };

    const property = await fetch(
      `https://supnerstai-ckbfb9hhguafd5au.centralindia-01.azurewebsites.net/discovery/properties/${id}`
    );

    if (!property.ok) return { error: "Property not found" };

    const data = await property.json();

    return {
      success: "Property Fetched SuccessFully",
      data: data,
    };
  } catch (error) {
    return { error: error.message };
  }
}

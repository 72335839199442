import React from "react";
import { NavbarHome } from "./_components/navbar";
import { HeroSection } from "./_components/HeroSection";
import { LocalitiesSection } from "./_components/localities";
import { PropertyList } from "./_components/properties";
import { Footer } from "./_components/footer";

const HomePage = () => {
  return (
    <div className=" w-full">
      <NavbarHome />
      <HeroSection />
      <LocalitiesSection />
      <PropertyList />
      <Footer />
    </div>
  );
};

export default HomePage;

/**
 *
 */
export async function AllProperties() {
  try {
    const response = await fetch(
      "https://supnerstai-ckbfb9hhguafd5au.centralindia-01.azurewebsites.net/discovery/properties"
    );

    if (!response.ok) {
      return { error: "Failed to fetch properties" };
    }

    const data = await response.json(); // Parse the JSON data
    return {
      success: `Properties Fetched !`,
      data: data,
    };
  } catch (error) {
    return { error: error.message };
  }
}

import React from "react";

const ServicesList = ({ services }) => {
  return (
    <div className="max-w-[830px] my-6 ">
      {/* Heading */}
      <h3 className="text-sm font-semibold text-gray-700 mb-2">Services</h3>

      {/* Services Container */}
      <div className="flex flex-wrap gap-2 p-4 bg-white rounded-xl shadow-lg border border-gray-200">
        {services?.slice(0, 5).map((service, idx) => (
          <span
            key={idx}
            className="bg-gray-100 text-gray-700 text-xs font-medium px-3 py-1 rounded-full"
          >
            {service}
          </span>
        ))}

        {/* Show additional count if more than 5 services */}
        {services?.length > 5 && (
          <span className="bg-gray-100 text-gray-700 text-xs font-medium px-3 py-1 rounded-full">
            + {services.length - 5} more
          </span>
        )}
      </div>
    </div>
  );
};

// Example Usage
// const servicesData = [
//   "AC",
//   "Attached Washroom",
//   "High Speed Wifi",
//   "Laundry Service",
//   "Storage Shelf",
//   "Parking",
//   "Gym Access",
//   "Swimming Pool",
// ];

export const ListingServices = ({ property }) => {
  return (
    <ServicesList
      // services={servicesData}
      services={property?.services}
    />
  );
};

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PropertyImages = ({ updateImagesData }) => {
  const [images, setImages] = useState([]);
  const [ownerDetails, setOwnerDetails] = useState({ name: "", phone: "" });
  const [referralDetails, setReferralDetails] = useState({
    name: "",
    phone: "",
  });
  const [errors,] = useState({});

  // Function to convert images to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle image upload
  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);

    // Convert each file to base64
    const base64Images = await Promise.all(
      files.map((file) => convertToBase64(file))
    );

    setImages((prevImages) => [...prevImages, ...base64Images]);
    toast.success("Images uploaded successfully!");
  };

  // Update parent component with image and owner/referral data
  useEffect(() => {
    updateImagesData(images, ownerDetails, referralDetails);
  }, [images, ownerDetails, referralDetails, updateImagesData]);

  // Handle owner details change
  const handleOwnerDetailsChange = (field, value) => {
    setOwnerDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  // Handle referral details change
  const handleReferralDetailsChange = (field, value) => {
    setReferralDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  // Validation Logic
  // const validateForm = () => {
  //   const newErrors = {};
  //   if (!ownerDetails.name) newErrors.ownerName = "Owner name is required.";
  //   if (!ownerDetails.phone)
  //     newErrors.ownerPhone = "Owner phone number is required.";
  //   if (!referralDetails.name)
  //     newErrors.referralName = "Referral name is required.";
  //   if (!referralDetails.phone)
  //     newErrors.referralPhone = "Referral phone number is required.";
  //   if (images.length < 5)
  //     newErrors.images = "Please upload at least 5 images.";
  //   setErrors(newErrors);

  //   // Return true if no errors
  //   return Object.keys(newErrors).length === 0;
  // };

  // Handle form submission
  // const handleSubmit = () => {
  //   if (validateForm()) {
  //     toast.success("Form submitted successfully!");
  //   } else {
  //     toast.error("Please fill all the required fields.");
  //   }
  // };

  return (
    <div className="bg-white flex justify-center p-4 sm:p-8">
      <ToastContainer />
      <div className="w-full p-4">
        <h2 className="text-lg font-medium mb-4 sm:mb-2 ml-2 text-gray-800">
          Property Pictures
        </h2>

        <div className="border shadow-md rounded-2xl p-4 sm:p-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
            {/* Upload Images Section */}
            <div className="rounded-2xl p-4 sm:p-6 bg-blue-50 relative border border-gray-200">
              <div className="mb-4">
                <p className="text-sm font-medium text-gray-700">
                  Pictures of reception, lobby, amenities, etc.
                </p>
                <p className="text-xs text-gray-500">
                  Please add at least 5 pictures here
                </p>
              </div>
              <div className="flex justify-center items-center w-full h-48 sm:h-60 rounded-xl bg-blue-50 border-blue-300 relative">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageUpload}
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
                {/* Show preview if images are selected */}
                {images.length > 0 ? (
                  <div className="flex gap-2 overflow-x-auto w-full">
                    {images.map((image, index) => (
                      <div key={index} className="relative">
                        <img
                          src={image}
                          alt="Uploaded"
                          className="w-24 h-24 rounded-lg object-cover"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <div className="bg-blue-100 rounded-full p-3 sm:p-4 mb-3 shadow-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    </div>
                    <p className="text-gray-600 font-medium">Upload images</p>
                    <p className="text-gray-400 text-xs sm:text-sm">
                      .jpg or .png files less than 5MB
                    </p>
                  </div>
                )}
              </div>
              {errors.images && (
                <p className="text-red-500 text-xs mt-1">{errors.images}</p>
              )}
            </div>

            {/* Owner and Referral Details Section */}
            <div className="flex flex-col space-y-6">
              {/* Owner Details */}
              <div>
                <div className="flex items-center mb-2">
                  <h3 className="text-sm font-medium text-gray-700 bg-gray-200 px-4 py-2 rounded-full">
                    Owner Details
                  </h3>
                  <hr className="flex-grow ml-4 border-t border-gray-300" />
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Owner Name
                    </label>
                    <input
                      type="text"
                      value={ownerDetails.name}
                      onChange={(e) =>
                        handleOwnerDetailsChange("name", e.target.value)
                      }
                      placeholder="Enter the owner's name"
                      className={`mt-1 block w-full rounded-lg border ${
                        errors.ownerName ? "border-red-500" : "border-gray-300"
                      } shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                    />
                    {errors.ownerName && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.ownerName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Owner Phone Number
                    </label>
                    <input
                      type="text"
                      value={ownerDetails.phone}
                      onChange={(e) =>
                        handleOwnerDetailsChange("phone", e.target.value)
                      }
                      placeholder="Enter the owner's phone number"
                      className={`mt-1 block w-full rounded-lg border ${
                        errors.ownerPhone ? "border-red-500" : "border-gray-300"
                      } shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                    />
                    {errors.ownerPhone && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.ownerPhone}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Referral Details */}
              <div>
                <div className="flex items-center mb-2">
                  <h3 className="text-sm font-medium text-gray-700 bg-gray-200 px-4 py-2 rounded-full">
                    Referral Details
                  </h3>
                  <hr className="flex-grow ml-4 border-t border-gray-300" />
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Referral Name
                    </label>
                    <input
                      type="text"
                      value={referralDetails.name}
                      onChange={(e) =>
                        handleReferralDetailsChange("name", e.target.value)
                      }
                      placeholder="Enter the referral's name"
                      className={`mt-1 block w-full rounded-lg border ${
                        errors.referralName
                          ? "border-red-500"
                          : "border-gray-300"
                      } shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                    />
                    {errors.referralName && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.referralName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Referral Phone Number
                    </label>
                    <input
                      type="text"
                      value={referralDetails.phone}
                      onChange={(e) =>
                        handleReferralDetailsChange("phone", e.target.value)
                      }
                      placeholder="Enter the referral's phone number"
                      className={`mt-1 block w-full rounded-lg border ${
                        errors.referralPhone
                          ? "border-red-500"
                          : "border-gray-300"
                      } shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                    />
                    {errors.referralPhone && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.referralPhone}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          {/* <div className="flex justify-end mt-6">
            <button
              onClick={handleSubmit}
              className="bg-indigo-500 text-white px-6 py-2 rounded-md hover:bg-indigo-600 transition duration-300"
            >
              Submit
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PropertyImages;

import React, { useState } from "react";
import { FaHeart, FaUser } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiOutlineSearch } from "react-icons/hi";

export const SearchNavbar = ({ onSearch, loading }) => {
  const [, setLocation] = useState("Bordeaux"); // Default location
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    if (searchTerm.trim()) {
      setLocation(searchTerm);
      setSearchTerm("");
      if (onSearch) {
        onSearch(searchTerm); // Trigger the search function passed from the parent
      }
    }
  };

  return (
    <div className="flex items-center justify-between p-6 max-w-7xl mx-auto">
      {/* Logo Section */}
      <div className="flex items-center space-x-2">
        <img src="/logo.png" alt="Logo" className="h-6 w-6" />
        <h2 className="text-xl font-semibold">
          <span className="font-bold text-black">supernest</span>.ai
        </h2>
      </div>

      {/* Location Search */}
      <div className="flex items-center space-x-2 bg-white shadow-md rounded-full px-4 py-2 text-black">
        <input
          type="text"
          placeholder="Enter location..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()} // Trigger search on Enter key
          className="outline-none bg-transparent flex-1 text-gray-700"
          disabled={loading}
        />
        <button
          disabled={loading}
          onClick={handleSearch}
          className="bg-blue-500 rounded-full p-1"
        >
          <HiOutlineSearch size={20} className="text-white" />
        </button>
      </div>

      {/* Right Section */}
      <div className="flex items-center space-x-4">
        {/* Favorite Icon */}
        <FaHeart className="text-gray-500" size={20} />

        {/* Nest Your Property Button */}
        <button className="bg-blue-600 text-white flex items-center px-4 py-2 rounded-full space-x-2 hover:bg-blue-700 transition duration-300">
          <IoIosCheckmarkCircleOutline size={20} />
          <span>Nest your Property</span>
        </button>

        {/* Login Button */}
        <button className="flex items-center text-blue-600 bg-blue-100 px-4 py-2 rounded-full space-x-2 hover:bg-blue-200 transition duration-300">
          <span>Login</span>
          <FaUser />
        </button>
      </div>
    </div>
  );
};

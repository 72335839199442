import React from "react";

export const HeroSection = () => {
  return (
    <div className="max-w-7xl mx-auto relative bg-[#F4F6FF] rounded-xl p-6 md:p-10 min-h-[350px]">
      {/* Background Image */}
      <div className="flex justify-center items-center">
        <img
          src="/hero-section.png"
          alt="hero background"
          className="w-full h-auto rounded-xl"
        />
      </div>

      {/* Heading */}
      <div className="absolute inset-0 flex flex-col items-center justify-center px-4">
        <h1 className="text-3xl sm:text-4xl md:text-6xl  text-gray-800 text-center leading-tight font-CaslonRegular">
          Find your nest with <br />
          <span className="text-[#0D64F3] font-CaslonRegular ">
            SuperMatch <span className=" text-[8px]">TM</span>
          </span>
        </h1>
      </div>

      {/* Responsive Search Bar */}
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-full px-4 sm:px-0 sm:-translate-y-8 flex flex-col sm:flex-row items-center bg-white rounded-lg shadow-lg p-3 space-y-3 sm:space-y-0 sm:space-x-3 mx-auto max-w-xs sm:max-w-md md:max-w-2xl md:-bottom-16 ">
        {/* Location Selector */}
        <div className="flex items-center space-x-2 pl-4 w-full sm:w-auto">
          <span className="text-blue-600 font-medium">Guntur</span>
          <svg
            className="w-4 h-4 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>

        {/* Search Input */}
        <input
          type="text"
          placeholder="Search areas, cities or names..."
          className="flex-grow outline-none text-gray-700 pl-4 py-2 w-full sm:w-auto rounded-md"
        />

        {/* Detect Location Button */}
        <button className="  flex items-center bg-[#E8F0FE] text-blue-600 rounded-full px-4 py-2 space-x-2 w-full sm:w-auto justify-center">
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
            <path d="M12 2a10 10 0 00-10 10 10 10 0 0014.39 9.23l5.33 1.37-1.37-5.33A10 10 0 0012 2zm0 18a8 8 0 010-16 8 8 0 010 16z" />
            <path d="M12 6.5a5.5 5.5 0 015.5 5.5 5.5 0 01-11 0 5.5 5.5 0 015.5-5.5z" />
          </svg>
          <span>Detect location</span>
        </button>

        {/* Search Button */}
        <button className=" flex items-center bg-blue-500 text-white rounded-full px-6 py-2 w-full sm:w-auto justify-center ">
          <svg className="w-5 h-5 mr-1" fill="currentColor" viewBox="0 0 24 24">
            <path d="M21 21l-4.35-4.35a8.5 8.5 0 111.42-1.42L21 21zm-10-4a6 6 0 100-12 6 6 0 000 12z" />
          </svg>
          Search
        </button>
      </div>
    </div>
  );
};

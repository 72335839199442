import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Replace with your actual Google Maps API Key
const GOOGLE_MAPS_API_KEY = "AIzaSyCceJ_PFXKkrZC0vkJo1GPL1dD2IeB4NPI";

const PropertyForm = ({ updateFormData }) => {
  // State to manage form fields
  const [propertyName, setPropertyName] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [center, setCenter] = useState({ lat: 20.5937, lng: 78.9629 }); // Default to India's coordinates
  const [selectedCity, setSelectedCity] = useState(null);
  const [completeAddress, setCompleteAddress] = useState({
    buildingName: "",
    streetName: "",
    locality: "",
  });
  const mapRef = useRef(null);

  // Ensure window.google and window.google.maps are available before rendering Markers
  const isGoogleMapsAvailable =
    typeof window !== "undefined" && window.google && window.google.maps;

  // State for validation
  const [errors, setErrors] = useState({});

  // Predefined list of available services
  const services = [
    "Meal Subscription",
    "High Speed Wifi",
    "Laundry",
    "Power Backup",
    "Housekeeping",
    "Gym",
    "Washing Machine",
    "Hot Water",
    "Television",
    "Water Purifier",
    "24x7 Surveillance",
  ];

  // Toggle service selection
  const toggleService = (service) => {
    setSelectedServices((prevServices) =>
      prevServices.includes(service)
        ? prevServices.filter((item) => item !== service)
        : [...prevServices, service]
    );
  };

  // Function to update map center based on selected city
  const updateMapCenter = (lat, lng) => {
    setCenter({ lat, lng });
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  // Handle city selection using Google Places Autocomplete
  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);

    if (selectedOption && selectedOption.value.place_id) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { placeId: selectedOption.value.place_id },
        (results, status) => {
          if (status === "OK" && results[0]) {
            const location = results[0].geometry.location;
            updateMapCenter(location.lat(), location.lng());
          } else {
            console.error("Error fetching city coordinates:", status);
          }
        }
      );
    }
  };

  // Handle current location fetching using the Geolocation API
  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          updateMapCenter(latitude, longitude);
        },
        (error) => {
          console.error("Error fetching location:", error);
          toast.error("Unable to fetch your location. Please try again.");
        }
      );
    } else {
      toast.error("Geolocation is not supported by your browser.");
    }
  };

  // Update parent component with form data when there's a change
  useEffect(() => {
    // Ensure no infinite loop by adding a check to only update when necessary
    if (updateFormData) {
      updateFormData({
        name: propertyName,
        city: selectedCity?.label || "",
        services: selectedServices,
        completeAddress,
        location: {
          lat: center.lat,
          lng: center.lng,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyName, selectedCity, selectedServices, center, completeAddress]);

  // Validation Logic
  const validateForm = () => {
    const newErrors = {};
    if (!propertyName) newErrors.propertyName = "Property name is required.";
    if (!selectedCity) newErrors.selectedCity = "City selection is required.";
    if (!completeAddress.buildingName)
      newErrors.buildingName = "Building name is required.";
    if (!completeAddress.streetName)
      newErrors.streetName = "Street name is required.";
    if (!completeAddress.locality) newErrors.locality = "Locality is required.";
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      // Perform submit actions if validation passes
      toast.success("Form submitted successfully!");
    } else {
      toast.error("Please fill all the required fields.");
    }
  };

  // Map container style
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  // Locate button style for current location
  const locateButtonStyle = {
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "10px",
    zIndex: 10,
    padding: "8px",
  };

  return (
    <div className="bg-white flex justify-center p-4 sm:p-8">
      <ToastContainer />
      <div className="p-4 w-full">
        {/* Header */}
        <div className="flex items-center mb-6 border-b-2 border-gray-200 pb-4 sm:pb-8">
          <div className="flex items-center space-x-2">
            <img src="/logo.png" alt="Logo" className="h-8 w-8" />
            <h2>
              <span className="font-bold">supernest</span>.ai
            </h2>
          </div>
          <h1 className="text-lg sm:text-xl font-semibold ml-4 sm:ml-12">
            Nest your PG
          </h1>
        </div>

        {/* Property Information Section */}
        <div>
          <h2 className="text-base sm:text-lg ml-2 mb-2 font-semibold">
            Property Information
          </h2>
        </div>
        <div className="border shadow-md rounded-2xl p-4 sm:p-6">
          {/* Property Information */}
          <div className="border-b pb-4 mb-4 sm:pb-6 sm:mb-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Property Name
                </label>
                <input
                  type="text"
                  value={propertyName}
                  onChange={(e) => setPropertyName(e.target.value)}
                  placeholder="Enter property name here..."
                  className={`mt-2 block w-full rounded-lg border ${
                    errors.propertyName ? "border-red-500" : "border-gray-300"
                  } shadow-sm p-2 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                />
                {errors.propertyName && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.propertyName}
                  </p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  City
                </label>
                <GooglePlacesAutocomplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  selectProps={{
                    selectedCity,
                    onChange: handleCityChange,
                    placeholder: "Select your city here...",
                    isClearable: true,
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        padding: "0.25rem",
                        borderRadius: "0.5rem",
                        borderColor: errors.selectedCity ? "red" : "#D1D5DB",
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                      }),
                    },
                  }}
                  apiOptions={{ componentRestrictions: { country: "in" } }}
                />
                {errors.selectedCity && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.selectedCity}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Services Section */}
          <div className="mb-4 sm:mb-6">
            <h2 className="text-base sm:text-lg font-medium mb-2 sm:mb-4">
              Services
            </h2>
            <div className="flex flex-wrap gap-2">
              {services.map((service, index) => (
                <button
                  key={index}
                  onClick={() => toggleService(service)}
                  className={`px-4 py-2 rounded-full border ${
                    selectedServices.includes(service)
                      ? "bg-indigo-500 text-white"
                      : "text-gray-700"
                  } focus:outline-none`}
                >
                  {service}
                </button>
              ))}
            </div>
          </div>

          {/* Address and Map Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
            <div className="rounded-md overflow-hidden h-72 sm:h-full relative">
              <div
                style={locateButtonStyle}
                onClick={fetchCurrentLocation}
                className="sm:top-4 sm:right-4"
              >
                <span
                  className="material-icons"
                  style={{ fontSize: "24px", color: "#007BFF" }}
                >
                  my_location
                </span>
              </div>
              <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                {isGoogleMapsAvailable && (
                  <>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={13}
                      onLoad={(map) => (mapRef.current = map)}
                    >
                      <Marker position={center} />
                    </GoogleMap>
                  </>
                )}
              </LoadScript>
            </div>
            <div>
              <div className="p-6 rounded-xl shadow-md">
                <p className="text-gray-500">
                  {selectedCity && selectedCity.label
                    ? selectedCity.label
                    : "Select a city"}
                </p>
              </div>

              {/* Complete Address Section */}
              <div className="mt-4 flex items-center">
                <h3 className="text-sm font-medium text-black bg-gray-200 px-4 py-2 rounded-full">
                  COMPLETE ADDRESS
                </h3>
                <hr className="flex-grow ml-4 border-t border-gray-300" />
              </div>
              <div className="mt-4 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Building Name/Door Number
                  </label>
                  <input
                    type="text"
                    value={completeAddress.buildingName}
                    onChange={(e) =>
                      setCompleteAddress((prev) => ({
                        ...prev,
                        buildingName: e.target.value,
                      }))
                    }
                    className={`mt-1 block w-full rounded-lg border ${
                      errors.buildingName ? "border-red-500" : "border-gray-300"
                    } shadow-sm p-1.5 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {errors.buildingName && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.buildingName}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Street Name
                  </label>
                  <input
                    type="text"
                    value={completeAddress.streetName}
                    onChange={(e) =>
                      setCompleteAddress((prev) => ({
                        ...prev,
                        streetName: e.target.value,
                      }))
                    }
                    className={`mt-1 block w-full rounded-lg border ${
                      errors.streetName ? "border-red-500" : "border-gray-300"
                    } shadow-sm p-1.5 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {errors.streetName && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.streetName}
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Locality
                  </label>
                  <input
                    type="text"
                    value={completeAddress.locality}
                    onChange={(e) =>
                      setCompleteAddress((prev) => ({
                        ...prev,
                        locality: e.target.value,
                      }))
                    }
                    className={`mt-1 block w-full rounded-lg border ${
                      errors.locality ? "border-red-500" : "border-gray-300"
                    } shadow-sm p-1.5 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                  />
                  {errors.locality && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.locality}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end mt-6">
            <button
              onClick={handleSubmit}
              className="bg-indigo-500 text-white px-6 py-2 rounded-md hover:bg-indigo-600 transition duration-300"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyForm;

import React from "react";
import { FiMail, FiPhone } from "react-icons/fi";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

export const Footer = () => {
  return (
    <footer className="bg-gray-50 border-t border-gray-200 py-8 px-4 md:px-10">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between space-y-8 md:space-y-0">
        {/* Support Section */}
        <div className="flex flex-col space-y-2 md:w-1/3">
          <h3 className="font-semibold text-gray-800">Support</h3>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Help centre
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            AirCover
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Anti-discrimination
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Disability support
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Cancellation options
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Report neighbourhood concern
          </a>
        </div>

        {/* Hosting Section */}
        <div className="flex flex-col space-y-2 md:w-1/3">
          <h3 className="font-semibold text-gray-800">Hosting</h3>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Nest your property
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            AirCover for hosts
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Hosting resources
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Community forum
          </a>
          <a href="/" className="text-gray-600 hover:text-gray-800">
            Hosting responsibly
          </a>
        </div>

        {/* Contact Information Section */}
        <div className="flex flex-col space-y-4 md:w-1/3">
          <div className="flex items-center space-x-2">
            <div className="text-blue-600 text-2xl">
              {/* <span role="img" aria-label="logo">
                🏠
              </span> */}
              <img src="/logo.png" alt="Logo" className="h-6 w-6" />
            </div>
            <span className="text-gray-800 font-semibold text-lg">
              supernest.ai
            </span>
          </div>
          <p className="text-gray-600">
            Find your perfect thriving, lively place to call home. Browse
            hundreds of dorms, hostels, and rental properties ready to move in.
          </p>
          <div className="flex items-center space-x-2">
            <FiMail className="text-gray-600" />
            <a
              href="mailto:info@supernest.ai"
              className="text-gray-600 hover:text-gray-800"
            >
              info@supernest.ai
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <FiPhone className="text-gray-600" />
            <a
              href="tel:+919876543210"
              className="text-gray-600 hover:text-gray-800"
            >
              +91 98765 43210
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-8 border-t border-gray-200 pt-6 flex flex-col md:flex-row items-center justify-between text-sm text-gray-500 space-y-4 md:space-y-0 md:max-w-7xl  md:mx-auto">
        <span>
          2024 © Alra AI LLP ·{" "}
          <a href="/" className="hover:text-gray-800">
            Privacy
          </a>{" "}
          ·{" "}
          <a href="/" className="hover:text-gray-800">
            Terms
          </a>
        </span>
        <div className="flex items-center space-x-4">
          <span>English (GB)</span>
          <span>GBP</span>
          <div className="flex space-x-3">
            <a href="/" className="text-gray-500 hover:text-gray-800">
              <FaFacebook />
            </a>
            <a href="/" className="text-gray-500 hover:text-gray-800">
              <FaTwitter />
            </a>
            <a href="/" className="text-gray-500 hover:text-gray-800">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

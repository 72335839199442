import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { HiMenu, HiX } from "react-icons/hi";

export const NavbarListing = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div className="relative flex items-center justify-between p-4 md:p-6 max-w-7xl mx-auto">
      {/* Logo Section */}
      <div
        onClick={() => (window.location.href = `/`)}
        className="flex items-center space-x-2 hover:cursor-pointer"
      >
        <img src="/logo.png" alt="Logo" className="h-6 w-6 md:h-8 md:w-8" />
        <h2 className="text-lg md:text-xl font-bold">
          <span className="text-black">supernest</span>.ai
        </h2>
      </div>

      {/* Center Navigation Links (Hidden on Mobile) */}
      <div className="hidden md:flex items-center space-x-8">
        <a href="/" className="text-gray-800 hover:text-blue-600 transition">
          For Tenants
        </a>
        <a href="/" className="text-gray-800 hover:text-blue-600 transition">
          For Property Owners
        </a>
        <a href="/" className="text-gray-800 hover:text-blue-600 transition">
          FAQ
        </a>
      </div>

      {/* Right Side - Buttons */}
      <div className="hidden md:flex items-center space-x-4">
        <button className="flex items-center bg-[#E8F0FE] text-blue-600 rounded-full px-4 py-2">
          <FaUser className="mr-2" />
          Sign In
        </button>
        <button className="bg-blue-500 text-white rounded-full px-6 py-2">
          Nest your property
        </button>
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden flex items-center">
        <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          {isMobileMenuOpen ? (
            <HiX size={28} className="text-gray-600" />
          ) : (
            <HiMenu size={28} className="text-gray-600" />
          )}
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10"
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      )}

      {/* Mobile Menu */}
      <div
        className={`fixed inset-y-0 right-0 w-3/4 max-w-xs bg-white shadow-lg z-20 transform transition-transform duration-300 ${
          isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col items-start space-y-4 p-6">
          <button
            onClick={() => setIsMobileMenuOpen(false)}
            className="self-end mb-4 text-gray-600"
          >
            <HiX size={24} />
          </button>

          <a href="/" className="text-gray-800 hover:text-blue-600 transition">
            For Tenants
          </a>
          <a href="/" className="text-gray-800 hover:text-blue-600 transition">
            For Property Owners
          </a>
          <a href="/" className="text-gray-800 hover:text-blue-600 transition">
            FAQ
          </a>

          <button className="flex items-center bg-[#E8F0FE] text-blue-600 rounded-full px-4 py-2 mt-6">
            <FaUser className="mr-2" />
            Sign In
          </button>
          <button className="bg-blue-500 text-white rounded-full px-6 py-2 mt-4">
            Nest your property
          </button>
        </div>
      </div>
    </div>
  );
};

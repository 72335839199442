import React from "react";
import { MdOutlinePhoneCallback } from "react-icons/md";
import { FiShare2, FiMapPin } from "react-icons/fi";

const PropertyDetailCard = ({ property }) => {
  return (
    <div className="max-w-7xl mx-auto p-4 md:flex md:space-x-8  rounded-lg">
      {/* Left Section - Image and Tags */}
      <div className="  md:w-2/3 flex flex-col ">
        <div className=" w-full relative">
          <img
            src={property?.images[0]}
            alt={property?.propertyInfo?.name}
            className="w-full h-[300px] md:h-[400px] rounded-lg object-cover"
          />

          {/* Tags on Image */}
          <div className="absolute top-4 left-4 flex space-x-2">
            {/* <span className="bg-black text-yellow-400 text-xs font-semibold px-2 py-1 rounded-md">
              ⭐ supernest verified
            </span>
            <span className="bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-md">
              🔹 Top Rated
            </span> */}
            {property?.supernestVerified === true && (
              <span className="bg-black text-yellow-400 text-[9px] font-semibold px-2 py-1 rounded-md">
                ⭐ supernest verified
              </span>
            )}
            {property?.topRated === true && (
              <span className="bg-blue-500 text-white text-[9px] font-semibold px-2 py-1 rounded-md">
                🔹 Top Rated
              </span>
            )}
          </div>

          {/* Room Types */}
          <div className="absolute bottom-4 left-4 flex space-x-2 text-white text-sm font-medium">
            <span className="bg-gray-800 px-2 py-1 rounded-lg flex items-center space-x-1">
              <FiMapPin size={14} />
              <span>Unisex</span>
            </span>
            <span className="bg-gray-800 px-2 py-1 rounded-lg">
              Single, Double, Triple
            </span>
          </div>
        </div>
        <div className=" flex flex-row items-center justify-between mt-3 ">
          {/* Left Side */}
          <div>
            <h1 className=" text-2xl font-[500]">
              {property?.propertyInfo?.name}
            </h1>
            <p className="text-gray-500 text-base">
              {property?.propertyInfo?.location}
            </p>
          </div>
          {/* Price Tag */}
          <div className=" flex flex-col">
            <p>Starts at</p>
            <div className=" bg-black text-white text-xl font-semibold px-3 py-2 rounded-lg">
              ₹{property?.price}/month
            </div>
          </div>
        </div>
      </div>

      {/* Right Section - Property Details */}
      <div className="md:w-1/3 mt-6 md:mt-0">
        <div className="bg-white p-6 rounded-lg shadow-lg space-y-4">
          {/* Title and Location */}
          {/* <div>
            <h2 className="text-2xl font-semibold text-gray-800">
              {property.title}
            </h2>
            <p className="text-gray-500">{property.location}</p>
          </div> */}

          {/* Price */}
          {/* <div>
            <p className="text-sm text-gray-500">Starts at</p>
            <p className="text-xl font-semibold text-black">
              ₹{property.price}/month
            </p>
          </div> */}
          <div className=" flex flex-row items-center justify-between mt-3 ">
            {/* Left Side */}
            <div>
              <h1 className=" text-xl font-[500]">
                {property?.propertyInfo?.name}
              </h1>
              <p className="text-gray-500 text-base">
                {property?.propertyInfo?.location}
              </p>
            </div>
            {/* Price Tag */}
            <div className=" flex flex-col">
              <p>Starts at</p>
              <div className=" bg-black text-white text-xl font-semibold px-3 py-2 rounded-lg">
                ₹{property?.price}/month
              </div>
            </div>
          </div>

          {/* Phone Number */}
          <div className="bg-[#E8F0FE] px-6 py-3 rounded-lg text-black text-3xl flex flex-col font-CaslonRegular">
            <span className=" text-[12px] font-CaslonRegular">
              Phone Number
            </span>
            {property?.propertyInfo?.phoneNumber}
          </div>

          {/* Request Callback Button */}
          <div className="flex items-center space-x-3 mt-4 w-full">
            <button className="bg-gray-100 text-blue-600 rounded-full px-3 py-2">
              <FiShare2 size={18} />
            </button>
            <button className="bg-blue-500 text-white rounded-full px-6 py-2 flex items-center justify-center w-full">
              <MdOutlinePhoneCallback size={18} className="mr-2" />
              Request 1-Click Callback
            </button>
          </div>

          {/* Amenities */}
          {/* <div className="flex flex-wrap gap-2 mt-4">
            {property?.amenities?.slice(0, 4).map((amenity, idx) => (
              <span
                key={idx}
                className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-1 rounded-full"
              >
                {amenity}
              </span>
            ))}
            {property.amenities.length > 4 && (
              <span className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-1 rounded-full">
                + {property.amenities.length - 4} more
              </span>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

// Example Property Data
// const exampleProperty = {
//   title: "Canberra House",
//   location: "Narsingi",
//   price: "42,109",
//   phoneNumber: "70924 82474",
//   amenities: [
//     "AC",
//     "Attached Washroom",
//     "High Speed Wifi",
//     "Laundry Service",
//     "Storage Shelf",
//     "Gym Access",
//     "Swimming Pool",
//   ],
//   image: "/property.jpeg",
//   isVerified: true,
//   isTopRated: true,
//   roomTypes: ["Single", "Double", "Triple"],
// };

export const ListingHeroSection = ({ property }) => {
  return (
    <PropertyDetailCard
      // property={exampleProperty}
      property={property}
    />
  );
};

import axios from "axios";

// Base API URL for the backend
const API_URL = "http://localhost:5000/api";

/**
 * Create a new property
 * @param {PropertyData} propertyData - The property data to create
 * @returns {Promise<Object>} - Object containing success status, message, and data
 */
export const createProperty = async (propertyData) => {
  try {
    const response = await axios.post(`${API_URL}/property`, propertyData);
    return {
      success: true,
      message: "Property created successfully",
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Error creating property",
      data: null,
    };
  }
};

/**
 * Get all properties
 * @returns {Promise<Object>} - Object containing success status, message, and data
 */
export const getProperties = async () => {
  try {
    const response = await axios.get(`${API_URL}/property`);
    return {
      success: true,
      message: "Properties fetched successfully",
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Error fetching properties",
      data: null,
    };
  }
};

/**
 * Get a single property by ID
 * @param {String} id - The ID of the property to fetch
 * @returns {Promise<Object>} - Object containing success status, message, and data
 */
export const getPropertyById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/property/${id}`);
    return {
      success: true,
      message: "Property fetched successfully",
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Error fetching property",
      data: null,
    };
  }
};

/**
 * Update a property by ID
 * @param {String} id - The ID of the property to update
 * @param {PropertyData} propertyData - The updated property data
 * @returns {Promise<Object>} - Object containing success status, message, and data
 */
export const updatePropertyById = async (id, propertyData) => {
  try {
    const response = await axios.put(`${API_URL}/property/${id}`, propertyData);
    return {
      success: true,
      message: "Property updated successfully",
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Error updating property",
      data: null,
    };
  }
};

/**
 * Delete a property by ID
 * @param {String} id - The ID of the property to delete
 * @returns {Promise<Object>} - Object containing success status and message
 */
export const deletePropertyById = async (id) => {
  try {
    await axios.delete(`${API_URL}/property/${id}`);
    return {
      success: true,
      message: "Property deleted successfully",
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || "Error deleting property",
    };
  }
};

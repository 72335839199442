import React, { useState, useCallback } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
  borderRadius: "1.5rem", // Apply rounded corners directly to the map
};

const center = {
  lat: 44.8378, // Centered near Bordeaux, France
  lng: -0.5792,
};

// Sample pricing data
const pricingData = [
  { id: 1, lat: 45.9, lng: -1.2, price: 110 },
  { id: 2, lat: 46.0, lng: -1.3, price: 150 },
  { id: 3, lat: 46.1, lng: -1.4, price: 500 },
  { id: 4, lat: 46.2, lng: -1.5, price: 275 },
  // Add more markers as needed
];

const MapWithPricing = () => {
  const [isSearchOnMove, setIsSearchOnMove] = useState(false);

  // Callback function for map movement
  const handleMapChange = useCallback(() => {
    if (isSearchOnMove) {
      // Logic to search properties as the map moves
      console.log("Searching properties as map moves...");
    }
  }, [isSearchOnMove]);

  return (
    <div className="relative h-screen w-full">
      {/* LoadScript wraps the map and provides the Google Maps API key */}
      <LoadScript googleMapsApiKey={"AIzaSyCceJ_PFXKkrZC0vkJo1GPL1dD2IeB4NPI"}>
        {/* Checkbox Overlay */}
        <div className="absolute top-4 left-96 z-10 bg-white p-2 rounded-md shadow-md flex items-center space-x-2">
          <input
            type="checkbox"
            checked={isSearchOnMove}
            onChange={(e) => setIsSearchOnMove(e.target.checked)}
          />
          <label>Search as I move the map</label>
        </div>

        {/* Google Map */}
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={7}
          onBoundsChanged={handleMapChange} // Triggered when map bounds change
        >
          {pricingData.map((location) => (
            <Marker
              key={location.id}
              position={{ lat: location.lat, lng: location.lng }}
              label={{
                text: `$${location.price}`,
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                background: "#333",
                padding: "10px",
                borderRadius: "20px",
              }}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapWithPricing;

import "./App.css";
import "./styles/fonts.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import CreateProperty from "./pages/create-property";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchPage from "./pages/search-page";
import ListingDetailPage from "./pages/property";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/property-form" element={<CreateProperty />} />
        <Route path="/property/:id" element={<ListingDetailPage />} />
        <Route path="/search" element={<SearchPage />} />
      </Routes>
    </Router>
  );
}

export default App;

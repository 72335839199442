/* global google */

import React, { useState, useEffect, useRef } from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { AiOutlineHeart } from "react-icons/ai";
import { MdOutlinePhoneCallback } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import { FaRegMap } from "react-icons/fa6";
import { AllProperties } from "../../../actions/get-All-Properties";
import { fetchGeocode } from "../../../utils/fetchGeocode";
import { Loader } from "@googlemaps/js-api-loader";

// Property Card for Map View
const PropertyCardMapView = ({ property }) => {
  return (
    <div className="flex flex-col md:flex-row bg-white rounded-lg space-y-16 md:space-y-0 relative border-b-2 border-gray-200 md:pb-20 lg:pb-6">
      <div className="absolute top-4 right-4">
        <AiOutlineHeart className="text-gray-400 hover:text-red-500 text-xl cursor-pointer" />
      </div>

      <div className="relative w-full md:w-[550px] h-[200px] mr-3">
        <img
          src={property.thumbnail || "/default-image.jpg"}
          alt={property.name}
          className="w-full h-full rounded-lg object-cover"
        />
        <div className="absolute top-2 left-2 flex space-x-2">
          {property.supernestVerified && (
            <span className="bg-black text-yellow-400 text-xs font-semibold px-2 py-1 rounded-md">
              ⭐ supernest verified
            </span>
          )}
          {property.topRated && (
            <span className="bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-md">
              🔹 Top Rated
            </span>
          )}
        </div>

        <div className="flex items-center space-x-3 mt-4 md:mt-5">
          <button className="bg-blue-100 px-4 py-2 flex items-center text-[12px] font-medium text-blue-600 rounded-full w-full md:w-auto justify-center">
            <MdOutlinePhoneCallback size={18} className="mr-1" />
            <span>Request Callback</span>
          </button>

          <button className="bg-blue-100 px-4 py-2 flex items-center text-[11px] font-medium text-blue-600 rounded-full w-full md:w-auto justify-center">
            <FiMapPin size={18} className="mr-1" />
            Visit Property
          </button>
        </div>
      </div>

      <div className="flex flex-col space-y-2 xl:space-y-6 w-full">
        <span className="text-sm text-gray-500">{property.location}</span>
        <h3 className="text-xl font-semibold text-gray-800">{property.name}</h3>
        <div className="border-b border-gray-200 w-1/5 my-2"></div>

        <div className="flex flex-wrap gap-2 mt-4">
          {property.services?.slice(0, 4).map((service, idx) => (
            <span
              key={idx}
              className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-1 rounded-full"
            >
              {service}
            </span>
          ))}
          {property.services?.length > 4 && (
            <span className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-1 rounded-full">
              + {property.services.length - 4} more
            </span>
          )}
        </div>
        <div className="border-b border-gray-200 w-1/5 my-2"></div>

        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center text-yellow-500">
            <span className="text-sm font-semibold">
              {property.rating || 4.5}
            </span>
            <span className="ml-1">⭐</span>
            <span className="text-sm text-gray-600 ml-2">
              ({property.reviews || 100} reviews)
            </span>
          </div>
          <div className="text-xl font-bold text-gray-800">
            ₹{property.minRent || "N/A"}{" "}
            <span className="text-sm text-gray-600">/month</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Property Card for List View
export const PropertyCardListView = ({ property }) => {
  return (
    <div
      onClick={() => (window.location.href = `/property/${property.id}`)}
      className="bg-white p-4 rounded-lg hover:cursor-pointer"
    >
      <div className="relative">
        <img
          src={property.thumbnail || "/property.jpeg"}
          alt={property.name}
          className="w-full h-48 rounded-lg object-cover"
        />
        <div className="absolute top-2 left-2 flex items-center justify-between space-x-2">
          {property.supernestVerified === true && (
            <span className="bg-black text-yellow-400 text-[9px] font-semibold px-2 py-1 rounded-md">
              ⭐ supernest verified
            </span>
          )}
          {property.topRated === true && (
            <span className="bg-blue-500 text-white text-[9px] font-semibold px-2 py-1 rounded-md">
              🔹 Top Rated
            </span>
          )}
        </div>
        <AiOutlineHeart className="absolute top-2 right-2 text-gray-400 hover:text-red-500 text-xl cursor-pointer" />
      </div>

      <div className="mt-4">
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-lg font-semibold text-gray-800">
            {property.name}
          </h3>
          <div className="text-sm text-gray-800 mt-2">
            from ₹{property.minRent || "N/A"}/month
          </div>
        </div>
        <span className="text-sm text-gray-500">{property.location}</span>

        <div className="flex flex-wrap gap-2 mt-4">
          {property.services?.slice(0, 4).map((service, idx) => (
            <span
              key={idx}
              className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-1 rounded-full"
            >
              {service}
            </span>
          ))}
          {property.services?.length > 4 && (
            <span className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-1 rounded-full">
              + {property.services.length - 4} more
            </span>
          )}
        </div>

        <div className="flex items-center space-x-3 mt-4">
          <button className="bg-blue-100 px-4 py-2 flex items-center text-[9px] font-medium text-blue-600 rounded-full justify-center">
            <MdOutlinePhoneCallback size={18} className="mr-1" />
            Request Callback
          </button>
          <button className="bg-blue-100 px-4 py-2 flex items-center text-[11px] font-medium text-blue-600 rounded-full justify-center">
            <FiMapPin size={18} className="mr-1" />
            Visit Property
          </button>
        </div>
      </div>
    </div>
  );
};

// Property List View
const PropertyListView = ({ properties }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    {properties.map((property, index) => (
      <PropertyCardListView key={index} property={property} />
    ))}
  </div>
);

// Property Map View
// const PropertyMapView = ({ properties, mapContainerStyle, center }) => {
//   const [propertyLocations, setPropertyLocations] = useState([]);
//   const [mapCenter, setMapCenter] = useState(null);
//   // Location Names of Properties so we want to get their lat lang .  we have maps api got from their .
//   // console.log(
//   //   "🚀 ~ Property Map View ------ >  ",
//   //   properties.map((item) => item.location)
//   // );

//   useEffect(() => {
//     const fetchCoordinates = async () => {
//       const locationsWithCoordinates = await Promise.all(
//         properties.map(async (property) => {
//           if (property?.coordinates) {
//             return property; // Use existing coordinates if available
//           }

//           // Fetch coordinates if not available
//           const coordinates = await fetchGeocode(property?.location);
//           return { ...property, coordinates };
//         })
//       );

//       setPropertyLocations(
//         locationsWithCoordinates.filter((property) => property?.coordinates)
//       );
//     };

//     fetchCoordinates();
//   }, [properties]);

//   const filteredProperties = properties.filter(
//     (property) => property.supernestVerified && property.topRated
//   );

//   // if (filteredProperties.length === 0) {
//   //   return (
//   //     <div className="text-center py-10">
//   //       <h2 className="text-2xl font-semibold text-gray-800 mb-4">
//   //         No Properties Available
//   //       </h2>
//   //       <p className="text-gray-600">
//   //         Currently, there are no properties that are both Supernest Verified
//   //         and Top Rated.
//   //       </p>
//   //     </div>
//   //   );
//   // }

//   return (
//     <div className="max-w-7xl mx-auto flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8 my-10">
//       <div className="w-full lg:w-1/2 space-y-6">
//         {filteredProperties.length === 0 ? (
//           <>
//             <div className="text-center py-10">
//               <h2 className="text-2xl font-semibold text-gray-800 mb-4">
//                 No Properties Available
//               </h2>
//               <p className="text-gray-600">
//                 Currently, there are no properties that are both Supernest
//                 Verified and Top Rated.
//               </p>
//             </div>
//           </>
//         ) : (
//           <>
//             {filteredProperties.map((property, index) => (
//               <PropertyCardMapView key={index} property={property} />
//             ))}
//           </>
//         )}
//       </div>

//       <div className="w-full lg:w-1/2 h-[400px] md:h-[500px] lg:h-[600px] bg-blue-100 rounded-lg relative">
//         <LoadScript googleMapsApiKey="AIzaSyCceJ_PFXKkrZC0vkJo1GPL1dD2IeB4NPI">
//           <GoogleMap
//             mapContainerStyle={mapContainerStyle}
//             center={center}
//             zoom={10}
//           >
//             {propertyLocations?.map(
//               (property, index) =>
//                 property?.coordinates && (
//                   <Marker
//                     key={index}
//                     position={{
//                       lat: property?.coordinates.lat,
//                       lng: property?.coordinates.lng,
//                     }}
//                     label={`₹${property?.minRent || "N/A"}`}
//                   />
//                 )
//             )}
//           </GoogleMap>
//         </LoadScript>
//         <div className="absolute top-4 right-4 bg-white px-4 py-2 rounded-lg shadow-md flex items-center space-x-2 text-gray-600">
//           <input type="checkbox" id="searchToggle" className="form-checkbox" />
//           <label htmlFor="searchToggle" className="text-sm">
//             Search as I move the map
//           </label>
//         </div>
//       </div>
//     </div>
//   );
// };

// Function to generate custom SVG icon URL for each price marker
const generatePriceMarkerIcon = (price) => {
  const svgIcon = `
    <svg width="50" height="30" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect x="0" y="0" width="50" height="30" rx="15" ry="15" fill="#ffffff" />
        <text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" font-size="14" font-family="Arial" font-weight="bold" fill="#333333">
          $${price}
        </text>
      </g>
    </svg>
  `;
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgIcon)}`;
};

const PropertyMapView = ({ properties, mapContainerStyle }) => {
  const mapRef = useRef(null); // Ref for the map container
  const [propertyLocations, setPropertyLocations] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);

  useEffect(() => {
    const fetchCoordinates = async () => {
      const locationsWithCoordinates = await Promise.all(
        properties.map(async (property) => {
          if (property?.coordinates) {
            return property; // Use existing coordinates if available
          }

          // Fetch coordinates if not available (implement fetchGeocode logic if needed)
          const coordinates = await fetchGeocode(property?.location);
          return { ...property, coordinates };
        })
      );

      const validLocations = locationsWithCoordinates.filter(
        (property) => property?.coordinates
      );
      setPropertyLocations(validLocations);

      if (validLocations.length > 0) {
        setMapCenter(validLocations[0].coordinates);
      } else {
        setMapCenter({ lat: 37.7749, lng: -122.4194 }); // Default fallback center (e.g., San Francisco)
      }
    };

    fetchCoordinates();
  }, [properties]);

  useEffect(() => {
    const initMap = async () => {
      if (!mapRef.current || !mapCenter) return;

      const loader = new Loader({
        apiKey: "AIzaSyCceJ_PFXKkrZC0vkJo1GPL1dD2IeB4NPI", // Replace with your actual Google Maps API key
        version: "weekly",
      });

      const { Map } = await loader.importLibrary("maps");

      // Map configuration
      const mapOptions = {
        center: mapCenter,
        zoom: 15,
        mapId: "MY_Nextjs_MAPID", // Customize map styling if applicable
      };

      // Initialize the map
      const map = new Map(mapRef.current, mapOptions);

      // // Add a custom center marker to the map
      // new google.maps.Marker({
      //   position: mapCenter,
      //   map,
      //   icon: {
      //     url: require("../../../assets/images/blue-marker.png"), // Use the custom center marker image
      //     scaledSize: new google.maps.Size(40, 40), // Adjust the size as needed
      //   },
      // });

      // Add property location markers to the map
      propertyLocations.forEach((property, index) => {
        if (property?.coordinates) {
          new google.maps.Marker({
            position: {
              lat: property.coordinates.lat,
              lng: property.coordinates.lng,
            },
            map,
            icon: {
              url: generatePriceMarkerIcon(property?.minRent || "0"),
              scaledSize: new google.maps.Size(50, 30),
            },
          });
        }
      });
    };

    initMap();
  }, [propertyLocations, mapCenter]);

  const filteredProperties = properties.filter(
    (property) => property.supernestVerified && property.topRated
  );

  return (
    <div className="max-w-7xl mx-auto flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8 my-10">
      <div className="w-full lg:w-1/2 space-y-6">
        {filteredProperties.length === 0 ? (
          <div className="text-center py-10">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              No Properties Available
            </h2>
            <p className="text-gray-600">
              Currently, there are no properties that are both Supernest
              Verified and Top Rated.
            </p>
          </div>
        ) : (
          filteredProperties.map((property, index) => (
            <PropertyCardMapView key={index} property={property} />
          ))
        )}
      </div>

      <div className="w-full lg:w-1/2 h-[400px] md:h-[500px] lg:h-[600px] bg-blue-100 rounded-lg relative">
        <div ref={mapRef} style={{ width: "100%", height: "100%" }}></div>
      </div>
    </div>
  );
};

export default PropertyMapView;

// Main Property List Component
export const PropertyList = () => {
  const [properties, setProperties] = useState(null);
  console.log("🚀 ~ PropertyList ~ properties:", properties);
  const [isMapOpened, setIsMapOpened] = useState(true);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const allProperties = await AllProperties();
        setProperties(allProperties.data);
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      }
    };

    fetchProperties();
  }, []);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "1.5rem",
  };

  const center = {
    lat: 44.8378,
    lng: -0.5792,
  };

  return (
    <div className="max-w-7xl mx-auto my-10">
      <div className="flex flex-row items-center justify-between mb-8 max-w-[630px]">
        <h2 className="text-2xl font-CaslonRegular text-gray-800">
          {isMapOpened ? "Trending Hostels" : "List View"}
        </h2>
        <button
          onClick={() => setIsMapOpened(!isMapOpened)}
          className="bg-blue-500 rounded-full px-4 py-2 text-white text-sm flex items-center space-x-2"
        >
          <span>{isMapOpened ? "Show List" : "Show Map"}</span>
          <FaRegMap size={18} />
        </button>
      </div>

      {properties ? (
        isMapOpened ? (
          <PropertyMapView
            properties={properties?.properties}
            mapContainerStyle={mapContainerStyle}
            center={center}
          />
        ) : (
          <PropertyListView properties={properties?.properties} />
        )
      ) : (
        <p>Loading properties...</p>
      )}
    </div>
  );
};
